import React from 'react';
import { NavLink } from 'react-router-dom';

function  MobileNav({ setShowMenu }) {
  return (
    <nav className="main-menu">
      <div className="container-fluid">
        <div className="main-menu__logo">
          <NavLink to="/">
            <img
              src="/assets/images/x.png"
              width={140}
              height={51}
              alt="Finlon"
            />
          </NavLink>
        </div>
        <div className="main-menu__nav">
          <ul className="main-menu__list">
            <li className="dropdown">
              <NavLink to="/">Inicio</NavLink>
            </li>
            <li>
              <NavLink to="/nosotros">Nosotros</NavLink>
            </li>
            <li className="dropdown" id="invertir">
              <NavLink to="/invertir">Invertir</NavLink>
            </li>
            <li className="dropdown" id="prestamos">
              <NavLink to="/prestamos">Préstamos</NavLink>
            </li>
            <li className="dropdown">
              <NavLink to="/contacto">Contáctanos</NavLink>
            </li>
          </ul>
        </div>
        <div className="main-menu__right">
          <a onClick={setShowMenu} className="main-menu__toggler mobile-nav__toggler">
            <i className="fa fa-bars" />
          </a>
          <a
            className="thm-btn-3 mobile-btn-menu"
            href="https://api.whatsapp.com/send?phone=51978866217&text=%C2%A1Quiero%20invertir%20con%20Crea%20Capital!%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F"
            target="_blank"
            rel="noopener noreferrer"
          >
            Quiero invertir
          </a>
          <a
            className="thm-btn-4 mobile-btn-menu"
            href="https://api.whatsapp.com/send?phone=51978864772&text=%C2%A1Hola!%20Quiero%20un%20pr%C3%A9stamo%20con%20garant%C3%ADa%20hipotecaria%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F"
            target="_blank"
            rel="noopener noreferrer"
          >
            Quiero un préstamo
          </a>
          <a href="tel:991983888" className="main-menu__contact">
            <span className="main-menu__contact__icon">
              <i className="icon-phone" />
            </span>
            <span className="main-menu__contact__text">
              <strong> 991 983 888</strong>
              Lun a Sáb: 9:00 a.m. a 6:00 p.m.
            </span>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default MobileNav;
