import { useState } from "react";

function PreguntasFrecuentesExtra() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content" />
      </div>
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/backgrounds/office-workers-using-finance-graphs.jpg)",
          }}
        />
        <div className="container"></div>
      </section>
      <section className="faq-grid pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-20">
            <div className="col-lg-12">
              <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                <div className={`accrodion ${activeAccordion === 0 ? "active" : ""}`} data-wow-delay="0ms">
                  <div className="accrodion-title color-secondary" onClick={() => handleAccordionClick(0)}>
                    <h4 className={` ${activeAccordion === 0 ? "color-secondary" : ""}`}
>
                      1. ¿Qué son los préstamos con garantía hipotecaria?
                      <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 0 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          Los préstamos con garantía hipotecaria son aquellos en los que el prestatario
                          para acceder al préstamo usa de respaldo un inmueble inscrito en SUNARP.
                          Esto significa que si el prestatario no cumple con los pagos del préstamo,
                          el prestamista tiene el derecho de ejecutar la garantía sobre la propiedad
                          para recuperar el dinero prestado. En el caso de Extra Capital, estos
                          préstamos están dirigidos a emprendedores y MYPEs que necesitan
                          financiamiento para hacer crecer o mejorar sus negocios.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className={`accrodion ${activeAccordion === 1 ? "active" : ""}`} data-wow-delay="0ms">
                  <div className="accrodion-title color-secondary" onClick={() => handleAccordionClick(1)}>
                    <h4 className=      {` ${activeAccordion === 1 ? "color-secondary" : ""}`}
>
                      2. ¿Cómo se ejecuta una garantía hipotecaria?
                      <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 1 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          La ejecución de una garantía hipotecaria ocurre cuando el prestatario no cumple
                          con las obligaciones de pago establecidas en el contrato del préstamo. El
                          proceso generalmente incluye los siguientes pasos:
                        </p>
                        <ul>
                          <p>
                            a. Notificación de Incumplimiento: Se envía una notificación al
                            prestatario informando sobre el incumplimiento y dando un plazo para
                            regularizar la situación.
                          </p>
                          <p>
                            b. Proceso Legal: Si el prestatario no regulariza la situación, se
                            iniciará un proceso legal para ejecutar la hipoteca.
                          </p>
                          <p>
                            c. Subasta Pública: La propiedad se pone en subasta pública. Los fondos
                            obtenidos de la venta se utilizan para pagar el saldo del préstamo, los
                            costos legales y cualquier otro gasto asociado.
                          </p>
                          <p>
                            d. Saldo Pendiente: Si el monto obtenido en la subasta no cubre el total
                            de la deuda, el prestatario aún podría ser responsable de pagar el saldo
                            restante.
                          </p>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>

                <div className={`accrodion ${activeAccordion === 2 ? "active" : ""}`} data-wow-delay="0ms">
                  <div className="accrodion-title color-secondary" onClick={() => handleAccordionClick(2)}>
                    <h4 className=      {` ${activeAccordion === 2 ? "color-secondary" : ""}`}
>
                      3. ¿Cómo pueden los préstamos con garantía hipotecaria que me ofrece Crea
                      Capital afectar el puntaje crediticio del prestatario? <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 2 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          Con Crea Capital los préstamos con garantía hipotecaria no afectan en el
                          puntaje crediticio de la persona que lo adquiere.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className={`accrodion ${activeAccordion === 3 ? "active" : ""}`} data-wow-delay="0ms">
                  <div className="accrodion-title color-secondary" onClick={() => handleAccordionClick(3)}>
                    <h4 className=      {` ${activeAccordion === 3 ? "color-secondary" : ""}`}
>
                      4. ¿Cómo determinar el valor de una propiedad para un préstamo con garantía
                      hipotecaria? <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 3 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>Para determinar el valor de una propiedad que se utilizará como garantía
                          hipotecaria, se siguen estos pasos:
                        </p>
                        <p>Tasación Profesional: Se contrata a un tasador profesional para evaluar la
                          propiedad. Este proceso incluye la inspección física del inmueble y el
                          análisis de comparables en el mercado.
                        </p>
                        <p> Documentación: Se recopilan documentos relevantes, como el Registro de
                          Propiedad y las características del inmueble.
                        </p>
                        <p> Evaluación del Mercado: Se considera la ubicación, el estado del mercado
                          inmobiliario, y las condiciones económicas actuales.
                        </p>
                        <p> Informe de Tasación: El tasador proporciona un informe detallado con el
                          valor estimado de la propiedad.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className={`accrodion ${activeAccordion === 4 ? "active" : ""}`} data-wow-delay="0ms">
                  <div className="accrodion-title color-secondary" onClick={() => handleAccordionClick(4)}>
                    <h4 className=      {` ${activeAccordion === 4 ? "color-secondary" : ""}`}
>
                      5. ¿Cómo se comparan los préstamos con garantía hipotecaria con otros tipos de préstamos?
                      <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 4 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          Los préstamos con garantía hipotecaria se comparan con otros tipos de préstamos en varios aspectos clave:
                        </p>
                        <table className="table">
                          <tbody>
                            <tr>
                              <td />
                              <td className="background-secondary text-white-p">
                                PRÉSTAMO CON
                                <br />
                                GARANTÍA HIPOTECARIA
                              </td>
                              <td className="background-secondary text-white-p">
                                PRÉSTAMO LIBRE O<br />
                                PERSONAL
                              </td>
                            </tr>
                            <tr>
                              <td className="background-secondary text-white-p">
                                GARANTÍA
                              </td>
                              <td>
                                DEBES CONTAR CON UN
                                <br />
                                INMUEBLE
                              </td>
                              <td>
                                DEBES CALIFICAR CON
                                <br />
                                LOS BANCOS
                              </td>
                            </tr>
                            <tr>
                              <td className="background-secondary text-white-p">
                                TASA DE INTERÉS
                              </td>
                              <td>BAJA</td>
                              <td>ALTA</td>
                            </tr>
                            <tr>
                              <td className="background-secondary text-white-p">
                                MONTO APROBADO
                              </td>
                              <td>
                                30% DEL VALOR DE TU
                                <br />
                                PROPIEDAD
                              </td>
                              <td>
                                PREVIA EVALUACIÓN DE
                                <br />
                                TUS INGRESOS
                                <br />
                                BANCARIZADOS
                              </td>
                            </tr>
                            <tr>
                              <td className="background-secondary text-white-p">
                                PLAZO DE PRÉSTAMO
                              </td>
                              <td>36 MESES</td>
                              <td>48 MESES</td>
                            </tr>
                            <tr>
                              <td className="background-secondary text-white-p">
                                REQUISITOS Y<br />
                                DOCUMENTACIÓN
                              </td>
                              <td>
                                ESTAR EN INFOCORP
                                <br />
                                NO ES IMPEDIMENTO
                              </td>
                              <td>
                                DEBES CALIFICAR EN EL
                                <br />
                                SISTEMA BANCARIO
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PreguntasFrecuentesExtra;
