function AgeFooter (){
    return(
        <div className="bottom-footer">
        <div className="container">
          <p className="bottom-footer__text text-center">
            Copyright © {new Date().getFullYear()} All Rights Reserved.
          </p>
        </div>
      </div>
    )
}
export default AgeFooter;