import { useState } from "react";

function PreguntasFrecuentesCrea() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content" />
        {/* /.sticky-header__content */}
      </div>
      {/* /.stricky-header */}
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/office-workers-using-finance-graphs.jpg)",
          }}
        />
        {/* /.page-header__bg */}
        <div className="container"></div>
        {/* /.container */}
      </section>
      {/* /.page-header */}
      <section className="faq-grid pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-20">
            <div className="col-lg-12">
              <div
                className="accrodion-grp faq-one-accrodion"
                data-grp-name="faq-one-accrodion-1"
              >
                {/*Start Faq One Single*/}
                <div
                  className={`accrodion ${
                    activeAccordion === 0 ? "active" : ""
                  }`}
                  data-wow-delay="0ms"
                >
                  <div
                    className="accrodion-title"
                    onClick={() => handleAccordionClick(0)}
                  >
                    <h4>
                      1. ¿Qué sucede si el prestatario no cumple con los
                      términos del préstamo?
                      <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 0 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          El inversionista de manera independiente o mediante
                          Crea Capital puede ejecutar la garantía hipotecaria,
                          es decir, se inicia el proceso legal para la
                          recuperación del capital mediante el remate judicial
                          de la propiedad
                        </p>
                        <p>
                          Si el inversionista no desea ejecutar la garantía Crea
                          Capital le ofrece otras opciones para recuperar su
                          capital en menor tiempo como el refinanciamiento o la
                          venta de la deuda.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                {/* End Faq One Single*/}
                {/*Start Faq One Single*/}
                <div
                  className={`accrodion ${
                    activeAccordion === 1 ? "active" : ""
                  }`}
                  data-wow-delay="0ms"
                >
                  <div
                    className="accrodion-title"
                    onClick={() => handleAccordionClick(1)}
                  >
                    <h4>
                      2. ¿Cómo se ejecuta una garantía hipotecaria?
                      <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 1 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          El proceso de ejecución de una garantía hipotecaria
                          comienza cuando el prestatario incumple con sus
                          obligaciones de pago según los términos del préstamo.
                        </p>
                        <ul>
                          <li>
                            Notificación de Incumplimiento: Se notifica
                            formalmente al prestatario sobre el incumplimiento y
                            la intención de ejecutar la garantía. Esta
                            notificación generalmente incluye un período de
                            gracia para que el prestatario regularice la
                            situación.
                          </li>
                          <li>
                            Proceso Legal: Si el prestatario no regulariza los
                            pagos dentro del período de gracia, se inicia un
                            proceso legal. Este proceso puede variar según la
                            jurisdicción, pero generalmente implica la
                            presentación de una demanda ante un tribunal.
                          </li>
                          <li>
                            Remate del inmueble: Una vez obtenido el fallo a
                            favor del inversionista, el tribunal ordena la venta
                            del inmueble en garantía. Esta venta puede ser a
                            través de una subasta pública o mediante una venta
                            privada, dependiendo de las leyes locales.
                          </li>
                          <li>
                            Distribución de Fondos: Los fondos obtenidos de la
                            venta del inmueble se utilizan para pagar la deuda
                            pendiente, incluidos los intereses y costos legales.
                            Si hay un excedente después de cubrir la deuda, este
                            se devuelve al prestatario.
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                {/* End Faq One Single*/}
                {/*Start Faq One Single*/}
                <div
                  className={`accrodion ${
                    activeAccordion === 2 ? "active" : ""
                  }`}
                  data-wow-delay="0ms"
                >
                  <div
                    className="accrodion-title"
                    onClick={() => handleAccordionClick(2)}
                  >
                    <h4>
                      3. ¿Cómo evitar los riesgos de una inversión con garantía
                      hipotecaria?
                      <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 2 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                         <strong>Diversificación:</strong> No coloques todo tu capital en un
                          solo préstamo. Diversificar en múltiples préstamos
                          reduce el riesgo de pérdidas significativas.
                        </p>
                        <p>
                          <strong>Análisis:</strong> Realiza un análisis detallado de cada
                          oportunidad de inversión, incluyendo la evaluación de
                          la propiedad en garantía y el perfil crediticio del
                          prestatario.
                        </p>
                        <p>
                          <strong>Trabajo con Profesionales:</strong> Asegúrate de trabajar con
                          empresas de inversión de buena reputación, como Crea
                          Capital, que ofrezcan un proceso transparente y
                          riguroso de evaluación de riesgos.
                        </p>
                        <p>
                          <strong>Revisión de documentación:</strong> Verifica que toda la
                          documentación legal esté en orden y que la propiedad
                          en garantía esté libre de cargas o disputas legales.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {/* End Faq One Single*/}
                {/*Start Faq One Single*/}
                <div
                  className={`accrodion ${
                    activeAccordion === 3 ? "active" : ""
                  }`}
                  data-wow-delay="0ms"
                >
                  <div
                    className="accrodion-title"
                    onClick={() => handleAccordionClick(3)}
                  >
                    <h4>
                      4. ¿Cómo evaluamos el riesgo de una inversión con garantía
                      hipotecaria?
                      <span className="accrodion-icon" />
                    </h4>
                  </div>
                  {activeAccordion === 3 && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          Valor del Inmueble en Garantía: Evaluamos el valor de
                          mercado del inmueble ofrecido como garantía y compara
                          con el monto del préstamo. Una mayor proporción de
                          valor de la propiedad en relación con el préstamo
                          reduce el riesgo.
                        </p>
                        <p>
                          Perfil del Prestatario: Analizamos el historial
                          crediticio del prestatario, su capacidad de generar
                          ingresos y su historial de pagos. Un prestatario con
                          buena solvencia reduce el riesgo de incumplimiento.
                        </p>
                        <p>
                          Condiciones del Mercado Inmobiliario: Consideramos el
                          estado y las tendencias del mercado inmobiliario
                          local. Mercados en crecimiento ofrecen más seguridad
                          en caso de necesitar ejecutar la garantía.
                        </p>
                        <p>
                          Condiciones del Préstamo: Revisamos los términos del
                          préstamo, incluidas las tasas de interés, plazos de
                          pago y condiciones de amortización. Préstamos con
                          términos claros y favorables son menos riesgosos.
                        </p>
                        <p>
                          Debida Diligencia: Realizamos una investigación
                          exhaustiva de todos los aspectos del préstamo y la
                          propiedad en garantía. Asegúrate de que no haya
                          problemas legales o de propiedad que puedan complicar
                          la ejecución de la garantía.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {/* End Faq One Single*/}
              </div>
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /.faq-grid */}
    </>
  );
}
export default PreguntasFrecuentesCrea;