import React, { useEffect,useState, useRef } from 'react';
import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay} from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import CarouselComponent from '../components/Carousel';

function Inicio() {

  const [loading, setloading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    message: ''
  });
  const slides = [
    { src: 'assets/images/case/morales.svg', alt: 'Morales' },
    { src: 'assets/images/case/experian.svg', alt: 'Experian' },
    { src: 'assets/images/case/notaria.svg', alt: 'Notaria' },
    { src: 'assets/images/case/SBS.svg', alt: 'SBS' },
    { src: 'assets/images/case/sentinel.svg', alt: 'Sentinel' },
    { src: 'assets/images/case/tinajeros.svg', alt: 'Tinajeros' },
  ];

  const cards = [
    {
      icon: 'assets/images/icons/expertos-financieros.svg',
      title: 'Expertos Financieros',
      text: 'Contamos con personal altamente calificados para brindar un servicio de calidad asegurándote una buena experiencia al invertir',
    },
    {
      icon: 'assets/images/icons/inicio_analisis.svg',
      title: 'Análisis Exhaustivo',
      text: 'Nos encargamos de analizar, evaluar y seleccionar las mejores oportunidades de inversión en el mercado.',
    },
    {
      icon: 'assets/images/icons/inicio_oportunidades.svg',
      title: 'Oportunidades Atractivas',
      text: 'Identificamos y ofrecemos opciones de inversión atractivas y sólidas.',
    },
    {
      icon: 'assets/images/icons/inicio_personalizacion.svg',
      title: 'Personalización',
      text: 'Adaptamos nuestras oportunidades de inversión de acuerdo a tus objetivos y perfil de riesgo.',
    },
    {
      icon: 'assets/images/icons/inicio_seguridad.svg',
      title: 'Tranquilidad y Seguridad',
      text: 'Estamos registrados en la SBS y cumplimos con las normativas correspondientes para que tú puedas invertir con confianza y tranquilidad.',
    }
  ];

  useEffect(() => {
    const addCustomClasses = () => {
      const owlCarouselElement = document.querySelector('.thm-owl__carousel');
      if (owlCarouselElement) {
        // Agregar clases necesarias
        owlCarouselElement.classList.add('owl-loaded'); // Agrega la clase .owl-loaded
        owlCarouselElement.classList.remove('no-js'); // Remueve la clase .no-js si está presente
      }
    };

    addCustomClasses();

  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Construye el mensaje de WhatsApp
    const whatsappMessage = `Hola, mis datos son:\n` +
                            `- Nombres: ${formData.name}\n` +
                            `- Apellidos: ${formData.lastname}\n` +
                            `- Correo: ${formData.email}\n` +
                            `- Celular: ${formData.phone}\n` +
                            `- Mensaje: ${formData.message}\n\n` +
                            `Me gustaría saber más sobre los servicios de Crea Capital.`;

    // Codifica el mensaje para la URL de WhatsApp
    const encodedMessage = encodeURIComponent(whatsappMessage);

    // Construye el enlace de WhatsApp
    const whatsappUrl = `https://wa.me/+51978866217?text=${encodedMessage}`;

    // Redirige a WhatsApp
    window.open(whatsappUrl, '_blank');
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

    return (
      <>
      {
      !loading?<>
      <CarouselComponent/>
      {/* /.slider-one */}
      <section className="about-four pt-120 mb-80">
        <div className="about-four__shape" />
        {/* /.about-four__shape */}
        <div className="container">
          <div className="row row-gutter-y-50">
            <div className="col-md-8">
              <div className="about-four__content">
                <div className="block-title text-left">
                  <h4 className="block-title__title">
                    {" "}
                    Conectamos con inversionistas y emprendedores{" "}
                  </h4>
                </div>
                {/* /.block-title */}
                <div className="about-four__box">
                  {/* /.about-four__box__content */}
                </div>
                {/* /.about-four__box */}
                <div className="row row-gutter-y-20">
                  <div className="col-md-8 ">
                    <div className="about-four__feature animated slideInLeft">
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation" />
                        </div>
                        {/* /.about-four__feature__icon */}
                        <h3 className="about-four__feature__title">
                          Conexión Estratégica
                        </h3>
                      </div>
                      {/* /.about-four__feature__content */}
                      <div className="about-four__feature__text">
                        En Crea Capital, conectamos a inversionistas con
                        emprendedores prometedores, creando oportunidades que
                        benefician a ambas partes.
                      </div>
                      {/* /.about-four__feature__text */}
                    </div>
                    {/* /.about-four__feature */}
                  </div>
                  {/* /.col-md-6 */}
                  <div className="col-md-8 backIn">
                    <div className="about-four__feature animated slideInLeft-2">
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation" />
                        </div>
                        {/* /.about-four__feature__icon */}
                        <h3 className="about-four__feature__title">
                          {" "}
                          Alianzas Sólidas{" "}
                        </h3>
                      </div>
                      {/* /.about-four__feature__content */}
                      <div className="about-four__feature__text">
                        Facilitamos alianzas estratégicas entre inversionistas y
                        emprendedores, impulsando el crecimiento y la innovación en
                        negocios locales.
                      </div>
                      {/* /.about-four__feature__text */}
                    </div>
                    {/* /.about-four__feature */}
                  </div>
                  {/* /.col-md-6 */}
                  <div className="col-md-8 backIn">
                    <div className="about-four__feature animated slideInLeft-3">
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation" />
                        </div>
                        {/* /.about-four__feature__icon */}
                        <h3 className="about-four__feature__title">
                          {" "}
                          Éxito Compartido{" "}
                        </h3>
                      </div>
                      {/* /.about-four__feature__content */}
                      <div className="about-four__feature__text">
                        Fomentamos una relación de éxito mutuo, donde los
                        inversionistas obtienen rendimientos atractivos y los
                        emprendedores acceden al financiamiento necesario para
                        expandir sus proyectos.
                      </div>
                      {/* /.about-four__feature__text */}
                    </div>
                    {/* /.about-four__feature */}
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/* /.about-four__content */}
            </div>
            {/* /.col-lg-6 */}
            <div className="col-md-4 mt-120">
              <div className="about-four__image animated bounceIn">
                <div className="about-four__image__bg mt-120" />
                {/* /.about-four__image__bg */}
                <div className="about-four__image__shape mt-120" />
                {/* /.about-four__image__shape */}
                <img
                  style={{ marginTop: 110 }}
                  src="assets/images/resources/crea_1.svg"
                  alt=""
                />
                <div className="about-four__image__caption">
                  <h3 className="about-four__image__caption__year">
                    175<i>+</i>
                  </h3>
                  <p className="about-four__image__caption__text">
                    Proyectos Concretados
                  </p>
                  {/* /.about-four__image__caption__text */}
                </div>
                {/* /.about-four__image__caption */}
                <div className="about-four__image__floated">oportunidades</div>
                {/* /.team-details__floated */}
              </div>
              {/* /.about-four__image */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      <section className="service-two mb--120">
        {/* <div class="container"> */}
        <div className="service-two__shape" />
        {/* /.service-two__shape */}
        <div className="row row-gutter-y-20 d-flex-center">
          <h2 className="block-title__title--white text-center">
            ¿Por qué elegirnos?
          </h2>
       <div className='col-lg-12'>
       <Swiper
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
                modules={[Navigation, Autoplay]}
                navigation={true}
                spaceBetween={50}
                loop={true}
              >
                {cards.map((card, index) => (
                  <SwiperSlide key={index} style={{borderRadius:'8px'}}>
            <div className="service-card-two">
              <div className="service-card-two__icon">
                <img
                  className="icon"
                  src={card.icon} 
                  alt={card.title}
                />
              </div>
              <div className="service-card-two__content pt-20">
                <h3 className="service-card-two__title text-center">
                  <a style={{fontSize:'20px'}}>{card.title}</a>
                </h3>
                <p className="service-card-two__text text-center">
                {card.text}
                </p>
              </div>
          
          </div>
                    {/* <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 wow fadeInLeft">
                      <div className="service-two__box">
                        <div className="service-two__box__content">
                          <h3 className="service-two__box__title">{card.title}</h3>
                          <p className="service-two__box__text">{card.text}</p>
                        </div>
                        <div className="service-two__box__icon">
                          <img src={card.icon} alt={card.title} />
                        </div>
                      </div>
                    </div> */}
                  </SwiperSlide>
                ))}
              </Swiper>
       </div>
        </div>
        {/* /.row */}
        {/*  </div> */}
      </section>
      {/* /.service-two */}
      <section
        className="video-one video-one--home pt-240 pb-120"
        style={{
          backgroundImage:
            "url(https://oficinascentenario.pe/wp-content/uploads/2022/08/DJI_0175-BLOG-1.jpg)"
        }}
      >
        {/*  <div class="video-one__shape"></div> */}
        {/* /.video-one__shape */}
        <div className="container">
          <div className="row row-gutter-y-50 ">
            <div className="col-lg-6">
              <div className="video-one__content">
                <a
                  href="https://www.youtube.com/watch?v=toKK-Xfqb3s"
                  className="video-popup video-one__btn"
                >
                  <i className="fa fa-play" />
                  <span className="ripple" />
                </a>
                {/* /.video-popup */}
                <h3 className="video-one__title">
                  Cientos de inversionistas han confiado <br /> en Crea Capital
                </h3>
              </div>
              {/* /.video-one__content */}
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <ul className="list-unstyled video-one__list">
                <li>
                  <i className="icon-tick" />
                  Asesoría Legal
                </li>
                <li>
                  <i className="icon-tick" />
                  Tasa Exclusiva
                </li>
                <li>
                  <i className="icon-tick" />
                  Cobranza y seguimiento
                </li>
                <li>
                  <i className="icon-tick" />
                  Proyectos de alta calidad
                </li>
                <li>
                  <i className="icon-tick" />
                  Plan de inversión personalizado
                </li>
                <li>
                  <i className="icon-tick" />
                  Registrados en la SBS
                </li>
              </ul>
              {/* /.list-unstyled video-one__list */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /.video-one */}
      <Swiper
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
      cssMode={true}
      navigation={true}
      spaceBetween={50}
      slidesPerView={1}
      modules={[Navigation,Autoplay]}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {slides.map((slide, index) => (
          <SwiperSlide key={index}>
          <div className="swiper-slide" key={index}>
          <img src={slide.src} alt={slide.alt}  width={250} height={250}/>
        </div>
        </SwiperSlide>
            ))}
    </Swiper>
    
      {/* /.client-carousel */}
      <section className="contact-form pt-80 pb-80">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-6 col-md-6">
              <div className="block-title">
                <h2 className="block-title__title--white">
                  Agenda una reunión con un asesor
                </h2>
              </div>
              <form onSubmit={handleSubmit} className="form-one contact-form-validated">
                <div className="row row-gutter-y-20 row-gutter-x-20">
                  <div className="col-md-6">
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Nombres"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Apellidos"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-input"
                      type="email"
                      placeholder="Correo"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Número de contacto"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-input"
                      placeholder="Mensaje"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="thm-btn br-4">
                      Consultar
                    </button>
                  </div>
                </div>
              </form>
              <div>Dentro de pronto un especialista se comunicará contigo</div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="google-map__default mt-40 br-4">
                <iframe
                  title="template google map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3903.453772071781!2d-77.0364316!3d-12.0969229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c85cd53b505d%3A0x8371f2ff41a418af!2sTorre%20El%20Pilar%2C%20Centro%20Camino%20Real%2C%20Av.%20Camino%20Real%20348%2C%20San%20Isidro%2015073!5e0!3m2!1sen!2spe!4v1641605258949!5m2!1sen!2spe"
                  className="map__default"
                  allowFullScreen=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /.faq-form */}
      </> 
      :(<div className="preloader">
            <div className="preloader__image" />
          </div>)
      }
      </>
    )
}
export default Inicio;