import { useState } from "react";

function NoticiasExtra() {
  const [openSectores, setOpenSectores] = useState(false);
  const [openOpciones, setOpenOpciones] = useState(false);
  const [openPrestamos, setOpenPrestamos] = useState(false);
  const [openEstrategias, setOpenEstrategias] = useState(false);
  const [openSeguridad, setOpenSeguridad] = useState(false);
  return (
    <>
     <div className="stricky-header stricked-menu main-menu">
    <div className="sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage:
          "url(assets/images/backgrounds/office-workers-using-finance-graphs.jpg)"
      }}
    />
    {/* /.page-header__bg */}
    <div className="container"></div>
    {/* /.container */}
  </section>
    <section className="faq-grid pt-120 pb-120">
      <div className="container">
        <div className="row row-gutter-y-20">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h2>
                  5 Estrategias Efectivas para Maximizar la Rentabilidad de Tu
                  Negocio en Perú
                </h2>
              </div>
              <div className="card-body">
                <p>
                  En el competitivo mundo empresarial peruano, maximizar la
                  rentabilidad es una prioridad crucial para cualquier negocio, ya
                  sea en el sector comercial, financiero o de servicios. Los retos
                  son múltiples y variados, y los errores internos pueden tener
                  consecuencias graves si no se abordan adecuadamente.
                </p>
                <button
                  className="btn btn-primary"
                  data-toggle="collapse"
                  onClick={() => setOpenSectores(!openSectores)}
                >
                  Ver más
                </button>
              </div>
              <div className={`collapse ${openSectores ? 'show' : ''}`}>
                <div className="card-body">
                  <h3>Principales Causas de Baja Rentabilidad</h3>
                  <ul>
                    <li>
                      <strong>Procesos Manuales Ineficientes:</strong> El uso de
                      métodos manuales para gestionar datos y archivos no solo es
                      lento, sino que también puede provocar errores y
                      dificultades en el acceso a información actualizada.
                    </li>
                    <li>
                      <strong>Enfoque Excesivo en la Competencia:</strong> Una
                      estrategia mal planificada que se centre exclusivamente en
                      competir en precios puede erosionar los márgenes de
                      beneficio.
                    </li>
                    <li>
                      <strong>Falta de Enfoque en el Cliente:</strong> No
                      priorizar la satisfacción y lealtad del cliente puede
                      resultar en una baja rotación y, por ende, en una menor
                      rentabilidad.
                    </li>
                  </ul>
                  <h3>Estrategias para Aumentar la Rentabilidad</h3>
                  <ol>
                    <li>
                      <strong>Reducción de Costos Innecesarios:</strong> Revisa
                      las horas extras de los empleados, elimina gastos
                      innecesarios y adopta prácticas sostenibles como el escaneo
                      de documentos, almacenamiento en la nube y uso de energías
                      renovables.
                    </li>
                    <li>
                      <strong>Optimización y Automatización de Procesos:</strong>{" "}
                      Implementar software actualizado y eficiente puede
                      simplificar flujos de trabajo, mejorar la comunicación
                      interna y eliminar redundancias.
                    </li>
                    <li>
                      <strong>Fidelización del Cliente:</strong> Desarrolla
                      programas de lealtad y mantén una comunicación constante con
                      tus clientes para identificar sus necesidades y mejorar su
                      experiencia.
                    </li>
                    <li>
                      <strong>Mejora de la Estructura de Precios:</strong> Analiza
                      tu estructura de costos y asegúrate de que tus precios
                      reflejen el valor de tus servicios sin comprometer tus
                      márgenes de beneficio.
                    </li>
                    <li>
                      <strong>Gestión de la Morosidad:</strong> Implementa
                      políticas de cobro eficientes y ofrece acuerdos de pago para
                      deudores. Utiliza un buen software CRM para gestionar y
                      analizar los datos de tus clientes morosos.
                    </li>
                  </ol>
                  <p>
                    Maximizar la rentabilidad de tu negocio en Perú requiere un
                    enfoque estratégico que aborde tanto los desafíos internos
                    como externos. Aplicar estas cinco estrategias efectivas puede
                    ayudarte a mejorar significativamente la rentabilidad y
                    asegurar la sostenibilidad de tu negocio.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2>
                  Préstamo con Garantía Hipotecaria: Seguridad y Oportunidades con
                  Extra Capital
                </h2>
              </div>
              <div className="card-body">
                <p>
                  Los préstamos con garantía hipotecaria se destacan como una
                  opción confiable y efectiva para obtener financiamiento. Este
                  tipo de préstamo permite a los propietarios de inmuebles
                  utilizar su propiedad como garantía para acceder a un capital
                  necesario para diversas necesidades, desde la expansión de un
                  negocio hasta la consolidación de deudas.
                </p>
                <button
                  className="btn btn-primary"
                  data-toggle="collapse"
                  data-target="#prestamo-hipotecario"
                >
                  Ver más
                </button>
              </div>
              <div className={`collapse ${openOpciones ? 'show' : ''}`} id="prestamo-hipotecario">
                <div className="card-body">
                  <h3>Importancia de los Préstamos con Garantía Hipotecaria</h3>
                  <ul>
                    <li>
                      <strong>Mayor Acceso a Financiamiento:</strong> Ofrecen
                      montos más altos en comparación con préstamos sin garantía,
                      ya que la propiedad respalda el préstamo.
                    </li>
                    <li>
                      <strong>Tasas de Interés Competitivas:</strong> Al estar
                      asegurados con un inmueble, suelen tener tasas de interés
                      más bajas.
                    </li>
                    <li>
                      <strong>Flexibilidad en el Uso del Dinero:</strong> El
                      capital obtenido puede utilizarse para múltiples fines, como
                      inversiones, mejoras del hogar o cubrir emergencias
                      financieras.
                    </li>
                  </ul>
                  <h3>¿Quiénes Pueden Beneficiarse?</h3>
                  <ul>
                    <li>
                      <strong>Propietarios de Inmuebles:</strong> Cualquier
                      persona que tenga una propiedad libre de cargas o con baja
                      hipoteca.
                    </li>
                    <li>
                      <strong>Empresarios y Emprendedores:</strong> Aquellos que
                      necesitan capital para invertir en sus negocios.
                    </li>
                    <li>
                      <strong>Particulares con Necesidades Financieras:</strong>{" "}
                      Para gastos importantes como educación, salud o
                      consolidación de deudas.
                    </li>
                  </ul>
                  <h3>Consideraciones Importantes</h3>
                  <ul>
                    <li>
                      <strong>Responsabilidad de Pago:</strong> Es crucial
                      mantener al día los pagos del préstamo para evitar la
                      ejecución de la garantía.
                    </li>
                    <li>
                      <strong>Asesoramiento Financiero:</strong> Consultar con un
                      asesor financiero puede ser beneficioso para entender
                      completamente las implicaciones del préstamo.
                    </li>
                  </ul>
                  <p>
                    Los préstamos con garantía hipotecaria representan una
                    solución financiera sólida y segura, especialmente para
                    aquellos que necesitan acceder a montos importantes de dinero.
                    Gracias a sus ventajas, como tasas de interés competitivas y
                    plazos de pago extendidos, son una opción atractiva tanto para
                    particulares como para empresarios. Es fundamental, sin
                    embargo, entender completamente las condiciones del préstamo y
                    asumir la responsabilidad del pago para evitar cualquier
                    riesgo sobre la propiedad ofrecida como garantía.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2>
                  Préstamos para Personas en Infocorp: Una Oportunidad con Extra
                  Capital
                </h2>
              </div>
              <div className="card-body">
                <p>
                  Encontrarse en Infocorp puede ser una barrera significativa para
                  obtener financiamiento en muchas instituciones financieras. Sin
                  embargo, Extra Capital ofrece una solución inclusiva y accesible
                  para aquellos que necesitan un préstamo, incluso si tienen un
                  historial crediticio desfavorable.
                </p>
                <button
                  className="btn btn-primary"
                  data-toggle="collapse"
                  onClick={() => setOpenPrestamos(!openPrestamos)}
                >
                  Ver más
                </button>
              </div>
              <div className={`collapse ${openPrestamos ? 'show' : ''}`}>
                <div className="card-body">
                  <h3>¿Qué es Infocorp?</h3>
                  <p>
                    Infocorp es una central de riesgos en Perú que recopila y
                    almacena información sobre el comportamiento crediticio de las
                    personas. Estar en Infocorp puede indicar que alguien ha
                    tenido dificultades para cumplir con sus obligaciones
                    financieras, lo que suele ser un impedimento para acceder a
                    nuevos créditos.
                  </p>
                  <p>
                    En situaciones donde se requiere un préstamo y se está
                    registrado en Infocorp, es fundamental buscar alternativas que
                    puedan ofrecer soluciones viables. Empresas financieras como
                    Crea Capital han desarrollado programas y políticas que
                    consideran diversos factores al evaluar solicitudes de
                    préstamos, brindando así oportunidades incluso a aquellos que
                    tienen un historial en Infocorp.
                  </p>
                  <p>
                    Es importante comprender que estar en Infocorp no
                    necesariamente cierra todas las puertas financieras. Con el
                    respaldo de un bien inmueble como garantía, se pueden
                    encontrar opciones de préstamos que se adapten a las
                    necesidades específicas de cada persona, brindando así la
                    oportunidad de obtener el financiamiento necesario para
                    diferentes propósitos.
                  </p>
                  <h3>Recomendaciones</h3>
                  <ul>
                    <li>
                      <strong>Investigar y Comparar:</strong> Investigar y
                      comparar las opciones disponibles en el mercado.
                    </li>
                    <li>
                      <strong>Entender los Términos y Condiciones:</strong>{" "}
                      Entender los términos y condiciones de cualquier acuerdo
                      financiero.
                    </li>
                    <li>
                      <strong>Asesoramiento Adecuado:</strong> Contar con el
                      asesoramiento adecuado.
                    </li>
                  </ul>
                  <p>
                    Aunque estar en Infocorp puede representar un obstáculo,
                    existen alternativas como los préstamos con garantía
                    hipotecaria que pueden ser consideradas para obtener el
                    financiamiento necesario, siempre y cuando se cumplan con los
                    requisitos y condiciones establecidos por la entidad
                    financiera correspondiente.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2>
                  El Papel Crucial de las Notarías en los Préstamos con Garantía
                  Hipotecaria
                </h2>
              </div>
              <div className="card-body">
                <p>
                  Cuando se trata de obtener un préstamo con garantía hipotecaria,
                  las notarías desempeñan un papel fundamental en todo el proceso.
                  Su función es asegurar que los documentos relacionados con la
                  propiedad inmueble y la hipoteca cumplan con los requisitos
                  legales y sean válidos ante la ley. A continuación, explicaremos
                  la importancia de las notarías en este contexto.
                </p>
                <button
                  className="btn btn-primary"
                  data-toggle="collapse"
                  onClick={() => setOpenEstrategias(!openEstrategias)}
                >
                  Ver más
                </button>
              </div>
              <div className={`collapse ${openEstrategias ? 'show' : ''}`} id="notary-role">
                <div className="card-body">
                  <h3>Legalidad y Validez</h3>
                  <p>
                    Las notarías verifican la autenticidad de los documentos, como
                    escrituras de propiedad, contratos de hipoteca y otros
                    documentos legales necesarios para respaldar el préstamo. Esto
                    garantiza que todas las transacciones sean legales y válidas.
                  </p>
                  <h3>Protección de los Derechos</h3>
                  <p>
                    Al participar en la firma y certificación de los documentos,
                    las notarías protegen los derechos tanto del prestamista como
                    del prestatario. Esto incluye asegurarse de que las
                    condiciones del préstamo estén claramente definidas y
                    entendidas por ambas partes.
                  </p>
                  <h3>Registro Público</h3>
                  <p>
                    Las notarías también se encargan de registrar los documentos
                    relacionados con la hipoteca en el registro público
                    correspondiente. Esto crea un registro legal de la hipoteca y
                    asegura que los derechos de la propiedad estén protegidos.
                  </p>
                  <h3>Transparencia y Seguridad</h3>
                  <p>
                    La intervención de una notaría proporciona transparencia y
                    seguridad en las transacciones financieras. Los procedimientos
                    notariales garantizan que todas las partes involucradas estén
                    informadas y protegidas durante el proceso.
                  </p>
                  <h3>Cumplimiento Normativo</h3>
                  <p>
                    Las notarías aseguran que el proceso de otorgamiento de
                    préstamos con garantía hipotecaria cumpla con las regulaciones
                    y normativas legales vigentes. Esto es crucial para evitar
                    problemas legales en el futuro.
                  </p>
                  <p>
                    Las notarías desempeñan un papel vital al garantizar la
                    legalidad, transparencia y seguridad en los préstamos con
                    garantía hipotecaria. Su intervención proporciona protección a
                    todas las partes involucradas y contribuye a un proceso
                    financiero sólido y confiable.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h2>
                  Cómo Evitar Caer en el Gota a Gota: Consejos para Protegerse
                </h2>
              </div>
              <div className="card-body">
                <p>
                  El "gota a gota" es una práctica peligrosa que involucra a
                  mafias criminales que ofrecen créditos informales y luego
                  utilizan la violencia y la extorsión para cobrar. Evitar caer en
                  esta trampa es crucial para protegerse de situaciones
                  financieras y personales extremadamente difíciles. Aquí te
                  presentamos algunos consejos para evitar convertirte en víctima
                  de esta práctica:
                </p>
                <button
                  className="btn btn-primary"
                  data-toggle="collapse"
                  onClick={() => setOpenSeguridad(!openSeguridad)}
                >
                  Ver más
                </button>
              </div>
              <div className={`collapse ${openSeguridad ? 'show' : ''}`} id="gotagota-tips">
                <div className="card-body">
                  <h3>Conocer tus opciones financieras legales</h3>
                  <p>
                    Antes de recurrir a créditos informales, investiga y conoce
                    las opciones legales disponibles, como préstamos bancarios,
                    cooperativas de crédito u otras instituciones financieras
                    reguladas por entidades gubernamentales.
                  </p>
                  <h3>Revisar y comprender los términos del préstamo</h3>
                  <p>
                    Si decides obtener un préstamo, asegúrate de leer y entender
                    completamente los términos y condiciones. Evita aceptar
                    préstamos con tasas de interés excesivamente altas o
                    condiciones poco claras que puedan resultar en problemas
                    financieros a largo plazo.
                  </p>
                  <h3>Evitar acuerdos verbales o informales</h3>
                  <p>
                    Siempre busca formalizar cualquier acuerdo de préstamo
                    mediante contratos escritos y legales. Esto te brindará
                    protección legal en caso de disputas o problemas con el
                    prestamista.
                  </p>
                  <h3>Mantener registros financieros claros</h3>
                  <p>
                    Lleva un registro detallado de tus transacciones financieras y
                    pagos de préstamos. Esto te ayudará a mantener un control
                    sobre tu situación financiera y a detectar cualquier
                    irregularidad o problema con los pagos.
                  </p>
                  <h3>Consultar con asesores financieros</h3>
                  <p>
                    Si tienes dudas o preocupaciones sobre tu situación financiera
                    o sobre la legitimidad de un préstamo, busca orientación y
                    asesoramiento con profesionales financieros o legales. Ellos
                    pueden brindarte información y recomendaciones para evitar
                    caer en situaciones de riesgo.
                  </p>
                  <h3>No ceder a la presión o la violencia</h3>
                  <p>
                    En caso de enfrentarte a amenazas o violencia por parte de
                    prestamistas informales, busca ayuda de inmediato. Denuncia
                    cualquier situación de extorsión o intimidación a las
                    autoridades competentes para proteger tu seguridad y
                    bienestar.
                  </p>
                  <h3>Promover la educación financiera</h3>
                  <p>
                    Fomenta la educación financiera y la conciencia sobre los
                    riesgos del "gota a gota" en tu comunidad. Informa a otros
                    sobre los peligros de esta práctica y cómo pueden protegerse
                    al tomar decisiones financieras responsables.
                  </p>
                  <p>
                    Al seguir estos consejos y estar alerta a las señales de
                    alerta, puedes reducir significativamente el riesgo de caer en
                    la trampa del "gota a gota" y proteger tu estabilidad
                    financiera y personal.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* /.col-lg-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
    {/* /.faq-grid */}
  </>
  
  
   
  );
}
export default NoticiasExtra;