import React from 'react';
import { Helmet } from 'react-helmet';

const LoadScripts = () => (
  <Helmet>
   <script defer src="/assets/js/combined.min.js"></script>
   <link rel="stylesheet" href="/assets/css/combined.min.css" />
   <link rel="stylesheet" href="/assets/vendors/finlon-icons/style.css" />
  </Helmet>
);

export default LoadScripts;
