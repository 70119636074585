import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';

const CarouselComponent = () => {
  // Opciones para Owl Carousel
  const owlOptions = {
    loop: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    items: 1,
    autoplay: true,
    autoplayTimeout: 7000,
    smartSpeed: 500,
    nav: false,
    dots: false,
    margin: 0,
  };

  return (
    <section className="slider-one">
      <OwlCarousel options={owlOptions} className="thm-owl__carousel thm-owl__carousel--custom-nav owl-carousel owl-theme owl-dot-style-one">
        <div className="item">
          <div className="slider-one__item">
            <div className="slider-one__lines">
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>

            <div className="slider-one__image" 
            style={{ backgroundImage: "url(	https://creacapital.com.pe/assets/images/backgrounds/invertir-1.png)" ,opacity: "0.5"}}></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <p className="slider-one__tagline">
                    <i className="fa fa-chart-pie" />
                    DE MANERA SIMPLE Y SEGURA
                  </p>
                  <h2 className="slider-one__title">
                    Te brindamos el poder y la libertad financiera
                  </h2>
                  <p className="slider-one__text">
                    Crea Capital Group empresa peruana registrada en la SBS
                  </p>
                  <div className="slider-one__btns" >               
                    <a target='_blank' href="https://api.whatsapp.com/send?phone=51978866217&text=%C2%A1Quiero%20invertir%20con%20Crea%20Capital!%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F" className="thm-btn-3">
                      Quiero Invertir
                    </a>
                    <a target='_blank' href="https://api.whatsapp.com/send?phone=51978864772&text=%C2%A1Hola!%20Quiero%20un%20pr%C3%A9stamo%20con%20garant%C3%ADa%20hipotecaria%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F" className="thm-btn-4">
                      Quiero un préstamo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
      <div className="slider-one__box wow fadeInRight" data-wow-duration="1500ms">
        <div className="slider-one__box__icon">
          <i className="icon-successful" />
        </div>
        <div className="slider-one__box__content">
          <p className="slider-one__box__tagline">Echa un vistazo a nuestros</p>
          <h3 className="slider-one__box__title">93% proyectos exitosos</h3>
        </div>
      </div>
    </section>
  );
};

export default CarouselComponent;
