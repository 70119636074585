import React, { useEffect, useRef } from 'react';
import noUiSlider from 'nouislider';
import LoanSimulator from '../components/Loan';

const Invertir = () => {
  const amountSliderRef = useRef(null);
  const monthsSliderRef = useRef(null);

  useEffect(() => {
    const amountSlider = amountSliderRef.current;
    const monthsSlider = monthsSliderRef.current;

    if (amountSlider && monthsSlider) {
      noUiSlider.create(amountSlider, {
        start: [15000],
        connect: [true, false],
        range: {
          min: 15000,
          max: 500000,
        },
        step: 1000,
      });

      amountSlider.noUiSlider.on('update', (values, handle) => {
        const amountValueElement = document.getElementById('amount-value');
        if (amountValueElement) {
          amountValueElement.innerHTML = `$${Math.round(values[handle])}`;
        }
      });

      noUiSlider.create(monthsSlider, {
        start: [6],
        connect: [true, false],
        range: {
          min: 6,
          max: 36,
        },
        step: 1,
      });

      monthsSlider.noUiSlider.on('update', (values, handle) => {
        const monthsValueElement = document.getElementById('months-value');
        if (monthsValueElement) {
          monthsValueElement.innerHTML = `${Math.round(values[handle])} Meses`;
        }
      });
    }

    // Cleanup
    return () => {
      if (amountSlider && monthsSlider) {
        amountSlider.noUiSlider.destroy();
        monthsSlider.noUiSlider.destroy();
      }
    };
  }, []);

    return (
        <>

  {/* /.stricky-header */}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/backgrounds/invertir-page.png)",
        opacity: "0.4"
      }}
    ></div>
    {/* /.page-header__bg */}
    <div className="container">
      <ul className="thm-breadcrumb list-unstyled">
        <li>
          <a href="index.html">Inicio</a>
        </li>
        <li>
          <span>Invertir</span>
        </li>
      </ul>
      {/* /.thm-breadcrumb list-unstyled */}
      <h2>
        ¡Invierte y obtén un retorno <br /> de hasta{" "}
        <span className="bold-yellow">34.49% </span>anual!
      </h2>
    </div>
    {/* /.container */}
  </section>
  {/* /.page-header */}
  <section className="blog-grid ">
    <div className="container">
      <section className="contact-form--white pt-80 pb-80">
        <div className="col-lg-12 col-md-12">
          <div className="container">
            <div className="row row-gutter-y-30">
              <div className="col-lg-12 col-md-6">
                <div className="block-title ">
                  <p className="block-title__tagline">INVERSIONES</p>
                  {/*    */}
                  {/* /.block-title__tagline */}
                  <h2 className="block-title__title">
                    {" "}
                    Financia tus sueños con garantía
                  </h2>
                  {/* /.block-title__title */}
                </div>
                {/* /.block-title */}
                <div
                  className="d-flex-center"
                  style={{ columnGap: "8%", marginBottom: 32 }}
                >
                  <div className="col-lg-4 service-card-three__icon--black center">
                    <div className="d-flex-center-gap-14 text-center pb-40">
                      <div className="service-card-two__icon">
                        <img
                          className="icon"
                          src="assets/images/icons/invertir_oportunidad.svg"
                        />
                      </div>
                      {/* /.service-card-two__icon */}
                      <span><strong>Oportunidades Exclusivas</strong></span>
                      <span>
                        {" "}
                        Nuestro enfoque en oportunidades de inversión con alto
                        potencial de crecimiento y rentabilidad, lo que te
                        permite obtener retornos <br /> atractivos y constantes.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 service-card-three__icon--black center">
                    <div className="d-flex-center-gap-14 text-center pb-40">
                      <div className="service-card-two__icon">
                        <img
                          className="icon"
                          src="assets/images/icons/inversion_rendimiento.svg"
                        />
                      </div>
                      {/* /.service-card-two__icon */}
                      <span><strong>Rendimiento Sólido</strong></span>
                      <span>
                      Nuestro enfoque en oportunidades de inversión
con alto potencial de crecimiento y rentabilidad, lo que te permite obtener retornos atractivos y constantes.{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row center">
                  <div className="col-lg-4 service-card-three__icon--black center">
                    <div className="d-flex-center-gap-14 text-center pb-40">
                      <div className="service-card-two__icon">
                        <img
                          className="icon"
                          src="assets/images/icons/inversion_transparencia.svg"
                        />
                      </div>
                      {/* /.service-card-two__icon */}
                      <span><strong>Transparencia y Seguridad</strong></span>
                      <span>
                        Al estar registrados en la SBS, operamos bajo estrictos
                        estándares de cumplimiento y transparencia, asegurando
                        que tus intereses estén protegidos en todo momento.{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 service-card-three__icon--black center">
                    <div className="d-flex-center-gap-14 text-center pb-40">
                      <div className="service-card-two__icon">
                        <img
                          className="icon"
                          src="assets/images/icons/inversion_asesoramiento.svg"
                        />
                      </div>
                      {/* /.service-card-two__icon */}
                      <span><strong>Asesoramiento Personalizado</strong></span>
                      <span>
                        Nuestro equipo estará a tu lado para brindarte
                        asesoramiento
                        <br /> personalizado en cada etapa del <br /> proceso.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 service-card-three__icon--black center">
                    <div className="d-flex-center-gap-14 text-center pb-40">
                      <div className="service-card-two__icon">
                        <img
                          className="icon"
                          src="assets/images/icons/inversión_garantia.svg"
                        />
                      </div>
                      {/* /.service-card-two__icon */}
                      <span><strong>Garantía Hipotecaria</strong></span>
                      <span>
                      Respalda al inversionista mediante un inmueble,
                        minimizando el riesgo y proporcionando potencial de
                        ingresos estables.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.container */}
        </div>
      </section>
      {/* /.faq-form */}
    </div>
    {/* /.container */}
  </section>
  {/* /.blog-grid */}
  <section
    className="service-two pt-120"
    style={{
      backgroundImage: "url(assets/images/resources/bg-simu.svg)",
      maxHeight: 980
    }}
  >
    <div className="container mt-40">
      <h2 className="block-title__title text-center hide-mobile">
        ¡Construye tu futuro financiero!
      </h2>
      <div className="row">
        <div className="col-lg-6 show-desktop">
          <div className="block-title text-left ">
            {/* <p class="block-title__tagline">SOBRE NOSOTROS</p> */}
            {/* /.block-title__tagline */}
            <div className="d-flex-center-gap-14--row mt-80 ">
              <img
                className=""
                style={{ width: 700 }}
                src="assets/images/resources/posteo-img.svg"
              />
            </div>
          </div>
        </div>
        {/* /.row */}
        <div className="col-lg-6">
          <div className="loan-calculator__box ">
            <div className="row row-gutter-x-0">
              <LoanSimulator/>
             
              {/* /.col-lg-6 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.loan-calculator__box */}
        </div>
      </div>
      {/* /.container */}
    </div>
  </section>
  <section className="service-two--white pt-120 pb-80">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="block-title text-left">
            {/* <p class="block-title__tagline">SOBRE NOSOTROS</p> */}
            {/* /.block-title__tagline */}
            <h2 className="block-title__title text-center ">
              Invierte en 4 simples pasos
            </h2>
            <div className="d-flex-center-gap-14--row mt-80">
              <div className="col-lg-5 service-card-three__icon--black center">
                <div className="d-flex-justify">
                  <div className="icon-4">
                    <i className="icon-user" style={{ color: "black" }} />
                  </div>
                  <p className=" mt-20 text-center">
                    1. Contáctanos y recibe asesoría personalizada con un
                    especialista.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 service-card-three__icon--black center">
                <div className="d-flex-justify">
                  <div className="icon-4">
                    <i className="icon-select" style={{ color: "black" }} />
                  </div>
                  <p className=" mt-20 text-center">
                    2. Conoce las oportunidades de inversión diponible y elige
                    la que prefieras.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex-center-gap-14--row mt-40">
              <div className="col-lg-5 service-card-three__icon--black center">
                <div className="d-flex-justify">
                  <div className="icon-4">
                    <i className="icon-successful" style={{ color: "black" }} />
                  </div>
                  <p className=" mt-20 text-center">
                    3. Firma de escritura pública en notaria
                  </p>
                </div>
              </div>
              <div className="col-lg-5 service-card-three__icon--black center">
                <div className="d-flex-justify">
                  <div className="icon-4">
                    <i className="icon-loan" style={{ color: "black" }} />
                  </div>
                  <p className=" mt-20 text-center">
                    4. Recibe pago mensual según cronograma
                  </p>
                </div>
              </div>
            </div>
            {/* /.block-title */}
          </div>
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  </section>
  <section className="service-two row">
    <div className="container">
      <div className="col-lg-12">
        <div className="row row-gutter-y-20 d-flex-center">
          <h2 className="block-title__title--white text-center">
            Tipos de Proyectos
          </h2>
          <div className="col-lg-4 col-md-12">
            <div className="service-card-two">
              <div className="service-card-two__shape" />
              {/* /.service-card-two__shape */}
              <div className="service-card-two__icon">
                <i className="icon-smartphone" />
              </div>
              {/* /.service-card-two__icon */}
              <div className="service-card-two__content">
                <h3 className="service-card-two__title">
                  <a href="services-details.html">
                    1. Financiamiento para <br /> Emprendimientos
                  </a>
                </h3>
                {/* /.service-card-two__title */}
                <p className="service-card-two__text" style={{fontSize:'14px'}}>
                  Ofrecemos oportunidades de inversión para emprendedores que
                  buscan capital para expandir o iniciar sus negocios,
                  proporcionando el respaldo financiero necesario para alcanzar
                  el éxito
                </p>
                {/* /.service-card-two__text */}
                {/* /.service-card-two__link */}
              </div>
              {/* /.service-card-two__content */}
            </div>
            {/* /.service-card-two */}
          </div>
          {/* /.col-lg-4 col-md-12 */}
          <div className="col-lg-4 col-md-12">
            <div className="service-card-two">
              <div className="service-card-two__shape" />
              {/* /.service-card-two__shape */}
              <div className="service-card-two__icon">
                <i className="icon-operation" />
              </div>
              {/* /.service-card-two__icon */}
              <div className="service-card-two__content">
                <h3 className="service-card-two__title">
                  <a href="services-details.html">2. Desarrollo</a>
                </h3>
                {/* /.service-card-two__title */}
                <p className="service-card-two__text" style={{fontSize:'14px'}}>
                  Facilitamos la inversión en proyectos dedicados al préstamo
                  con garantía hipotecaria, generando ingresos constantes y a
                  largo plazo.
                </p>
                {/* /.service-card-two__text */}
                {/* /.service-card-two__link */}
              </div>
              {/* /.service-card-two__content */}
            </div>
            {/* /.service-card-two */}
          </div>
          {/* /.col-lg-4 col-md-12 */}
          <div className="col-lg-4 col-md-12">
            <div className="service-card-two">
              <div className="service-card-two__shape" />
              {/* /.service-card-two__shape */}
              <div className="service-card-two__icon">
                <i className="icon-payment-gateway" />
              </div>
              {/* /.service-card-two__icon */}
              <div className="service-card-two__content">
                <h3 className="service-card-two__title">
                  <a href="services-details.html">
                    3. Inversiones en Negocios Establecidos
                  </a>
                </h3>
                {/* /.service-card-two__title */}
                <p className="service-card-two__text" style={{fontSize:'14px'}}>
                  Identificamos y ofrecemos oportunidades de inversión en
                  negocios consolidados, con potencial de crecimiento y
                  rentabilidad, permitiendo a los inversionistas diversificar su
                  portafolio y maximizar sus ganancias.
                </p>
                {/* /.service-card-two__text */}
                {/* /.service-card-two__link */}
              </div>
              {/* /.service-card-two__content */}
            </div>
            {/* /.service-card-two */}
          </div>
          {/* /.col-lg-4 col-md-12 */}
        </div>
      </div>
    </div>
  </section>
  {/* /.service-two */}
  <section className="service-one pt-120 pb-30">
    <img
      src="assets/images/shapes/service-h-1.png"
      className="service-one__icon-1"
      alt=""
    />
    <img
      src="assets/images/shapes/service-h-2.png"
      className="service-one__icon-2"
      alt=""
    />
    <img
      src="assets/images/shapes/service-h-3.png"
      className="service-one__icon-3"
      alt=""
    />
    <div className="container">
      <div className="block-title text-center">
        <h2 className="block-title__title">
          ¡Únete a nosotros y haz crecer tu capital de forma inteligente y
          confiable! <br />{" "}
        </h2>
        {/* /.block-title__title */}
        <a
            className="thm-btn-3 mt-40"
            href="https://api.whatsapp.com/send?phone=51978866217&text=%C2%A1Quiero%20invertir%20con%20Crea%20Capital!%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F"
            target="_blank"
            rel="noopener noreferrer"
          >
            Quiero invertir
          </a>
      </div>
      {/* /.block-title */}
    </div>
    {/* /.container */}
  </section>
  {/* /.service-grid pt-120 pb-120 */}
</>

    );
}
export default Invertir;