import { NavLink } from "react-router-dom";
import useScrollToTop from "../components/useScrollToTop";

function Footer () {
  useScrollToTop();
    return (
       
        <footer className="main-footer">
              <div className="container">
                <div className="row row-gutter-y-30">
                  <div className="col-lg-4 col-md-6">
                    <div className="footer-widget footer-widget--about">
                      <a href="index.html" className="footer-widget__logo">
                        <img
                          src="assets/images/logos/logo-crea-white.svg"
                          alt="finlon"
                          width={140}
                          height={51}
                        />
                      </a>
                      <p className="footer-widget__text" style={{ textAlign: "left" }}>
                        Somos una empresa peruana especializada en inversiones con
                        Garantía Hipotecaria.
                      </p>
                      <ul className="list-unstyled footer-widget__info" style={{ textAlign: "left" }}>
                        <li>
                          <i style={{ color: "#fff" }} className="icon-email" />
                          <a href="mailto:hola@creacapital.com.pe">
                            hola@creacapital.com.pe
                          </a>
                        </li>
                        <li style={{ textAlign: "left" }}>
                          <i className="icon-telephone" />
                          <a href="tel:954412310" > 954 412 310 </a>
                        </li>
                      </ul>
                      {/* /.list-unstyled */}
                    </div>
                    {/* /.footer-widget */}
                  </div>
                  {/* /.col-lg-4 */}
                  <div className="col-lg-2 col-md-6">
                    <div className="footer-widget footer-widget--links">
                      <h3 className="footer-widget__title">Menú</h3>
                      {/* /.footer-widget__title */}
                      <ul className="list-unstyled footer-widget__menu" style={{ textAlign: "left" }}>
                        <li>
                         <NavLink to="/">Inicio</NavLink>
                        </li>
                        <li>
                          <NavLink to="/nosotros">Nosotros</NavLink>
                        </li>
                        <li>
                        <NavLink to="/invertir">Invertir</NavLink>
                        </li>
                        <li>
                        <NavLink to="/prestamos">Préstamos</NavLink>
                        </li>
                        <li>
                        <NavLink to="/contacto">Contáctanos</NavLink>
                        </li>
                      </ul>
                      {/* /.list-unstyled footer-widget__menu */}
                    </div>
                    {/* /.footer-widget */}
                  </div>
                  {/* /.col-lg-2 */}
                  <div className="col-lg-3 col-md-6">
                    <div className="footer-widget footer-widget--time" style={{ textAlign: "left" }}>
                      <h3 className="footer-widget__title">Horario</h3>
                      {/* /.footer-widget__title */}
                      <p className="footer-widget__text">Lun a Sáb: 9:00 a.m. a 6:00 p.m.</p>
                      <div className="footer-widget__social" style={{ textAlign: "left" }}>
                        <a
                        style={{ textAlign: "left" }}
                          id="tk"
                          target="_blank"
                          href="https://www.tiktok.com/@crea.capital.pe?_t=8mIfBdnF5Of&_r=1"
                        >
                          <i className="fab fa-tiktok" />
                        </a>
                        <a
                          id="fb"
                          target="_blank"
                          href="https://www.facebook.com/crea.capital.pe/"
                        >
                          <i className="fab fa-facebook" />
                        </a>
                        <a
                          id="lnk"
                          target="_blank"
                          href="https://www.linkedin.com/company/91594266/admin/feed/posts/"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>
                        <a
                          id="ig"
                          target="_blank"
                          href="https://www.instagram.com/crea.capital.pe?igsh=MWtncnVvdzcwYThqcw%3D%3D&utm_source=qr"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                        
                      </div>
                     
                      <div>
                        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSem97FqL4M84Dyb7n_gWcXlu4CR3S8A9QFonPo9OEoUZ-V7PA/viewform">
                        <img  width={100} height={100} src="assets/images/icons/libro_reclamaciones.svg" alt="libro-reclamaciones" />

                        </a>
                        </div>

                      {/* /.footer-widget__social */}
                    </div>
                    {/* /.footer-widget */}
                  </div>
                  {/* /.col-lg-3 */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container */}
        </footer>
    )
}
export default Footer;