import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay} from 'swiper/modules';

import 'swiper/swiper-bundle.css';
import CountUp from 'react-countup';


function Nosotros() {

  const [modalOpen, setModalOpen] = useState(false);
  const downloadFile = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadRUC = () => {
    downloadFile('assets/files/Ficha_RUC.pdf', 'Ficha_RUC.pdf');
  };

  const handleDownloadSBS = () => {
    downloadFile('assets/files/Registro_SBS.pdf', 'Registro_SBS.pdf');
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const teamMembers = [
    {
      name: 'Ricardo del Valle',
      role: 'GERENTE GENERAL',
      image: 'assets/images/team/Ricardo.svg',
    },
    {
      name: 'Danilo Mathey Perich',
      role: 'GERENTE DE OPERACIONES',
      image: 'assets/images/team/Danilo.svg',
    },
/*     {
      name: 'Ariana Marquez',
      role: 'GERENTE DE MARKETING',
      image: 'assets/images/team/Ariana.svg',
    }, */
    {
      name: 'Mariela Cautti',
      role: 'ABOGADA',
      image: 'assets/images/team/Mariela.svg',
    },
    {
      name: 'Alex Paz',
      role: 'COORDINADOR COMERCIAL',
      image: 'assets/images/team/Alex.svg',
    },
    {
      name: 'Lucia Cruz',
      role: 'SOCIAL MEDIA MANAGEMENT',
      image: 'assets/images/team/Lucia.svg',
    },
  /*   {
      name: 'Andrés Carpio',
      role: 'DISEÑADOR GRÁFICO',
      image: 'assets/images/team/Andrés.svg',
    }, */
    {
      name: 'Kevin Berrospi',
      role: 'DIRECTOR DE INVERSIONES',
      image: 'assets/images/team/Kevin.svg',
    },
    {
      name: 'Alexandra Quintana',
      role: 'ASESOR COMERCIAL',
      image: 'assets/images/team/Alesandra.svg',
    },
    {
      name: 'Christian Fernández ',
      role: 'ASESOR COMERCIAL',
      image: 'assets/images/team/Christian.svg',
    },
    {
      name: 'Denilson Miranda',
      role: 'ASESOR COMERCIAL',
      image: 'assets/images/team/Denilson.svg',
    },
  ];

  const values = [
    {
      icon: "assets/images/icons/nosotros_integridad.svg",
      title: "Integridad",
      text: "Operamos con transparencia y honestidad en todas nuestras acciones y decisiones."
    },
    {
      icon: "assets/images/icons/nosotros_seguridad.svg",
      title: "Seguridad",
      text: "Empresa peruana registrada en la SBS, con equipo capacitado y eficiente en inversiones y préstamos, comprometida con altos estándares en servicios y relaciones, respaldando proyectos con garantías hipotecarias."
    },
    {
      icon: "assets/images/icons/nosotros_innovación.svg",
      title: "Innovación",
      text: "Buscamos constantemente nuevos proyectos y soluciones financieras para mejorar y crecer junto con nuestros clientes."
    },
    {
      icon: "assets/images/icons/nosotros _responsabilidad.svg",
      title: "Responsabilidad",
      text: "Actuamos con profesionalismo y responsabilidad, cumpliendo con las regulaciones y normativas del sector financiero."
    },
    {
      icon: "assets/images/icons/nosotros_colaboracion.svg",
      title: "Colaboración",
      text: "Fomentamos un ambiente de trabajo en equipo y cooperación para alcanzar objetivos comunes y crear valor para nuestros clientes y la comunidad."
    }
  ];
  
  return (
    <>
  <div className="stricky-header stricked-menu main-menu">
    <div className="sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/backgrounds/invierte.svg)",
        backgroundSize: "100%",
        opacity: "0.5"
      }}
    ></div>
    {/* /.page-header__bg */}
    <div className="container">
      <ul className="thm-breadcrumb list-unstyled">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>
          <span>SOBRE NOSOTROS</span>
        </li>
      </ul>
      {/* /.thm-breadcrumb list-unstyled */}
      <h2>
        Somos tu aliado para que tu capital <br /> crezca de forma segura y
        rentable
      </h2>
    </div>
    {/* /.container */}
  </section>
  {/* /.page-header */}
  <section className="about-two pt-120 pb-20">
    <div className="container">
      <div className="row row-gutter-y-50">
        <div className="col-lg-5">
          <div className="block-title text-left">
            {/* <p class="block-title__tagline">SOBRE NOSOTROS</p> */}
            {/* /.block-title__tagline */}
            <h2 className="block-title__title">SOBRE NOSOTROS</h2>
            {/* /.block-title__title */}
          </div>
          {/* /.block-title */}
          <div
            className="about-two__image--orange"
            style={{ top: "0%", position: "relative" }}
          >
            <img
              style={{ width: 480 }}
              src="assets/images/backgrounds/sobre_nosotros.svg"
              alt=""
            />
          </div>
          {/* /.about-two__image */}
        </div>
        <div className="col-lg-6 mt-120">
          <div className="about-two__content">
            <div className="block-title text-center">
              {/* <p class="block-title__tagline">SOBRE NOSOTROS</p> */}
              {/* /.block-title__tagline */}
              <h2 className="block-title__title" />
              {/* /.block-title__title */}
            </div>
            {/* /.block-title */}
            <p className="justify">
              Somos una empresa privada dedicada a crear oportunidad de negocio
              mediante la inversión con garantía hipotecaria ofreciendo la mejor
              rentabilidad y respaldo a nuestra comunidad de inversionistas. Con
              dos años en el mercado, Crea Capital se ha establecido firmemente
              en el ámbito de las inversiones y préstamos en el Perú.
              <br />
            </p>
            <p className="justify">
              Actuamos como intermediarios entre los inversionistas y
              emprendedores, creando alianzas estratégicas que impulsan el
              crecimiento económico y la innovación en negocios locales.
              Ofrecemos una sólida cartera de oportunidades de inversión
              respaldadas por garantías hipotecarias, garantizando seguridad y
              rentabilidad para nuestros clientes.
              <br />
            </p>
            <p className="justify">
              Nuestro éxito en diversos proyectos y nuestra excelente reputación
              son testimonio de nuestro compromiso con la transparencia y la
              excelencia en el servicio.
            </p>
          </div>
          {/* /.about-two__content */}
        </div>
        {/* /.col-lg-6 */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container */}
  </section>
  {/* /.about-two */}
  <section className="about-two  pt-10 pb-120 ">
    <div className="container">
      <div className="d-flex-center-gap-14--row">
        <div className="col-lg-5 border-base pt-20 pb-20 mr-20 mb-20">
          <ul className="list-unstyled ml-0 about-two__list">
            <li>
              {/*  <i class="fa fa-arrow-circle-right"></i> */}
              <strong className="text-16">Invertir en proyectos</strong>
              <p className="text-16">
                {" "}
                Si eres un inversionista en busca de proyectos con garantía de éxito y rendimientos atractivos.
              </p>
              <p>¡Solicita más información!</p>
              <a href="https://api.whatsapp.com/send?phone=51978866217&text=%C2%A1Quiero%20invertir%20con%20Crea%20Capital!%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F"
 className="thm-btn-3 main-menu__btn">
                Quiero invertir
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-5 border-secondary-2 pt-20 pr-20 mr-20 pb-20 mb-20">
          <ul className="list-unstyled ml-0 about-two__list">
            <li>
              <strong className="justify text-16">Solicitar un préstamo</strong>
              <p className="justify text-16" >
                Si eres un emprendedor en busca de capital  para llevar tu proyecto al siguiente nivel.
              </p>
              <p>¡Solicita más información!</p>
              <a   href="https://api.whatsapp.com/send?phone=51978864772&text=%C2%A1Hola!%20Quiero%20un%20pr%C3%A9stamo%20con%20garant%C3%ADa%20hipotecaria%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F"
            target="_blank" className="thm-btn-4 main-menu__btn ">
                Quiero un préstamo
              </a>
              
              {/* /.thm-btn */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="call-to-action-two pb-80">
    <div className="container">
      <div className="col">
        <div
          className="col-lg-12 wow fadeInLeft"
          data-wow-delay="000ms"
          data-wow-duration="1500ms"
        >
          <div className="call-to-action-two__content">
            <ul className="list-unstyled call-to-action-two__list">
              {/*    <li>Simple</li>
                          <li>Transparent</li>
                          <li>Secure</li> */}
            </ul>
            {/* /.call-to-action-two__list */}
            <div className="row">
              <div className="col-lg-4 ">
                <div className="d-flex-center-no-gap">
                  <i
                    className="icon-successful"
                    style={{ fontSize: 64, color: "#EDE632" }}
                  />
                  <h3 className="block-title__title--white">Misión</h3>
                </div>
              </div>
              <div className="col-lg-8">
                <p className="about-two__text justify">
                  Crear oportunidades de inversión mediante el desarrollo de
                  proyectos de negocio eficientes, siguiendo los más altos
                  estándares de calidad y cumpliendo con cada uno de nuestros
                  compromisos, con un talentoso equipo de trabajo en permanente
                  crecimiento, reflejado en productos que satisfagan las
                  necesidades del mercado.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /.col-lg-6 */}
        <div className="col-lg-12">
          <div className="call-to-action-two__content">
            <div className="row">
              <div className="col-lg-4 ">
                <div className="d-flex-center-no-gap">
                  <i
                    className="icon-growth"
                    style={{ fontSize: 64, color: "#EDE632" }}
                  />
                  <h3 className="block-title__title--white">Visión</h3>
                </div>
              </div>
              <div className="col-lg-8">
                <p className="about-two__text justify">
                  {" "}
                  Ser reconocida como un referente en el negocio de inversiones
                  financieras de manera rentable y sostenible, desarrollando
                  proyectos de negocio innovadores, creando experiencias únicas
                  en nuestros clientes, y manteniendo un modelo de gestión que
                  promueva el crecimiento y bienestar de nuestros colaboradores,
                  facilitando así el éxito de nuestros clientes y el desarrollo
                  económico del país.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.row */}
    </div>
    {/* /.container */}
  </section>
  <section
    className="video-one video-one--home pt-120 pb-120"
    style={{
      backgroundImage:
        "url(https://elperuano.pe/fotografia/thumbnail/2020/10/16/000094415M.jpg)"
    }}
  >
    <div className="container">
      <div className="row row-gutter-y-50">
        <div className="col-lg-6">
          <div className="video-one__content">
            <div className="video-popup video-one__btn" onClick={openModal}>
              <i className="fa fa-play" />
              <span className="ripple" />
            </div>
            <h3 className="video-one__title">
              REGISTRADOS <br /> EN LA SBS
            </h3>
          </div>
          {/* /.video-one__content */}
        </div>
        {/* /.col-lg-6 */}
        <div className="col-lg-6">
          {/* 
          <button id="downloadButton2" onClick={handleDownloadRUC}>
        Descargar Ficha RUC
      </button>
      <button id="downloadButton" onClick={handleDownloadSBS}>
        Descargar Registro SBS
      </button>
          */}
          <ul className="list-unstyled video-one__list">
            <li onClick={handleDownloadSBS}>
              <img
                className="icon"
                style={{ marginRight: 20 }}
                src="assets/images/icons/download.svg"
              />
              <p
                className="block-title__title--white text-14"
                id="downloadButton"
                
              >
                RESOLUCIÓN SBS <br />
              </p>
            </li>
            <br />
            <li onClick={handleDownloadRUC}>
              <img
                className="icon"
                style={{ marginRight: 20 }}
                src="assets/images/icons/download.svg"
              />
              <p
                className="block-title__title--white text-14"
                id="downloadButton2"
              >
                FICHA RUC
                <br />
              </p>
            </li>
          </ul>
          {/* /.list-unstyled video-one__list */}
        </div>
        {/* /.col-lg-6 */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container */}
  </section>
  {/* /.video-one */}
  {/* Modal */}

  {modalOpen && (
        <div id="videoModal" className="modal" style={{ display: 'flex' }}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              ×
            </span>
            <iframe
              id="iframe"
              src="https://www.youtube.com/embed/t5EI56HI-RI"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            />
          </div>
        </div>
      )}
  <section className="service-two">
    {/* <div class="container"> */}
    <div className="service-two__shape" />
    {/* /.service-two__shape */}
    <div className="row row-gutter-y-20 d-flex-center">
      <h2 className="block-title__title--white text-center">
        Nuestros Valores
      </h2>
      <div className='col-lg-12'>
       <Swiper
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
                modules={[Navigation, Autoplay]}
                navigation={true}
                spaceBetween={50}
                loop={true}
              >
                {values.map((card, index) => (
                  <SwiperSlide key={index} style={{borderRadius:'8px'}}>
            <div className="service-card-two">
              <div className="service-card-two__icon">
                <img
                  className="icon"
                  src={card.icon} 
                  alt={card.title}
                />
              </div>
              <div className="service-card-two__content pt-20">
                <h3 className="service-card-two__title text-center">
                  <a style={{fontSize:'20px'}}>{card.title}</a>
                </h3>
                <p className="service-card-two__text text-center">
                {card.text}
                </p>
              </div>
          
          </div>
                    {/* <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 wow fadeInLeft">
                      <div className="service-two__box">
                        <div className="service-two__box__content">
                          <h3 className="service-two__box__title">{card.title}</h3>
                          <p className="service-two__box__text">{card.text}</p>
                        </div>
                        <div className="service-two__box__icon">
                          <img src={card.icon} alt={card.title} />
                        </div>
                      </div>
                    </div> */}
                  </SwiperSlide>
                ))}
              </Swiper>
       </div>

    </div>
    {/* /.row */}
    {/*  </div> */}
  </section>
  {/* /.service-two */}
  <section className="fact-one pt-80 pb-40">
    <div className="container">
      <div className="row row-gutter-y-30">
        <div className="col-lg-4 col-md-6">
          <div className="fact-one__item">
          <div className="fact-one__count">
              <span className="count-box">
              <CountUp
                delay={2}
                className="count-text"
                start={0}
                end={136}
                duration={1.5}
                separator="," // Opcional: agrega un separador de miles si es necesario
                useEasing={true} // Usa easing para una animación más suave
                useGrouping={true} // Agrupa números grandes con comas
              />
              </span>
            </div>
            
            {/* /.fact-one__count */}
            <h3 className="fact-one__title">Proyectos</h3>
            {/* /.fact-one__title */}
          </div>
          {/* /.fact-one__item */}
        </div>
        {/* /.col-lg-3 col-md-6 */}
        <div className="col-lg-4 col-md-6">
          <div className="fact-one__item">
          <div className="fact-one__count">
              <span className="count-box">
                <CountUp
                            className="count-text"
                            delay={2}
                            start={0}
                            end={80}
                            duration={1.5}
                            separator="," // Opcional: agrega un separador de miles si es necesario
                            useEasing={true} // Usa easing para una animación más suave
                            useGrouping={true} // Agrupa números grandes con comas
                          />
              </span>
            </div>
            
            {/* /.fact-one__count */}
            <h3 className="fact-one__title">Inversionistas</h3>
            {/* /.fact-one__title */}
          </div>
          {/* /.fact-one__item */}
        </div>
        {/* /.col-lg-3 col-md-6 */}
        <div className="col-lg-4 col-md-6">
          <div className="fact-one__item">
          <div className="fact-one__count">
              <span className="count-box">
                <CountUp
                 delay={2}
                         className="count-text"
                         start={0} 
                         end={80}
                         duration={1.5}
                         separator="," // Opcional: agrega un separador de miles si es necesario
                         useEasing={true} // Usa easing para una animación más suave
                         useGrouping={true} // Agrupa números grandes con comas
                       />
              </span>
            </div>
            
            {/* /.fact-one__count */}
            <h3 className="fact-one__title">Empresarios</h3>
            {/* /.fact-one__title */}
          </div>
          {/* /.fact-one__item */}
        </div>
        {/* /.col-lg-3 col-md-6 */}
        {/* /.col-lg-3 col-md-6 */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container */}
  </section>
  {/* /.fact-one */}
  <section className="team-about pt-120 pb-120">
  <div className="block-title text-center pt-80">
      <h2 className="block-title__title">Nuestro Equipo </h2>
  </div>
  <Swiper
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      cssMode={true}
      navigation={true}
      spaceBetween={50}
      slidesPerView={1}
      modules={[Navigation,Autoplay]}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
    {teamMembers.map((member, index) => (
          <SwiperSlide key={index}>
            <div className="team-member">
              <img src={member.image} alt={member.name} width={120} height={120}/>
              <h3>{member.name}</h3>
              <p>{member.role}</p>
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
    </section>
  {/* /.team-about */}

  {/* /.main-footer */}

  {/* /.bottom-footer */}
</>

  );
}
export default Nosotros;