import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

function Topbar() {
  const [extra, setExtra] = useState(false);
  const location = useLocation(); // Hook de react-router-dom para obtener la ubicación actual

  useEffect(() => {
    setExtra(location.pathname === "/prestamos");
    if(location.pathname === "/noticias-extra"){
      setExtra(true);
    }
    if(location.pathname === "/preguntas-frecuentes-extra"){
      setExtra(true);
    }
  }, [location.pathname]); // location.pathname es ahora la dependencia

  return (
    <div className="topbar">
    <div className="container-fluid">
      <div className="topbar__info">
        <a href="#">
          <i className="icon-pin" /> Av. Camino Real 348 - Torre el Pilar
          of. 706, San Isidro
        </a>
        <a href="mailto:hola@creacapital.com.pe">
          <i style={{ color: "#fff" }} className="icon-email" />{" "}
          hola@creacapital.com.pe
        </a>
      </div>
      <div className="topbar__links">
        <NavLink to={`${extra?'/noticias-extra':'/noticias-crea'}`}>{`${extra?'Noticias Extra':'Noticias Crea'}`}</NavLink>
        <NavLink to={`${extra?'/preguntas-frecuentes-extra':'/preguntas-frecuentes-crea'}`}>FAQs</NavLink>
      </div>
      <div className="topbar__social">
        <a
          id="tk"
          target="_blank"
          href={`${extra?'https://www.tiktok.com/@crea.capital.pe?_t=8mIfBdnF5Of&_r=1':'https://www.tiktok.com/@crea.capital.pe?_t=8nm8eE2wETu&_r=1'}`}
        >
          <i className="fab fa-tiktok" />
        </a>
        <a
          id="fb"
          target="_blank"
          href={`${extra?'https://www.facebook.com/crea.capital.pe/':'https://www.facebook.com/crea.capital.pe/'}`}
        >
          <i className="fab fa-facebook" />
        </a>
        <a
          id="lnk"
          target="_blank"
          href={`${extra?'https://www.linkedin.com/company/91594266/admin/feed/posts/':'https://www.linkedin.com/company/crea-capital-pe/'}`}
        >
          <i className="fab fa-linkedin-in" />
        </a>
        <a
          id="ig"
          target="_blank"
          href={`${extra
            ?"https://www.instagram.com/crea.capital.pe?igsh=MWtncnVvdzcwYThqcw%3D%3D&utm_source=qr"
            :"https://www.instagram.com/crea.capital.pe?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="}`}
         >
         <i className="fab fa-instagram" />
        </a>
      </div>
    </div>
  </div>
  );
}   
export default Topbar;