import React, { useState, useRef } from 'react';
import ReactSlider from 'react-slider';

const LoanSimulatorExtra = () => {
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(199000);
  const [months, setMonths] = useState(6);
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    setStep(1);
    const whatsappURL = `https://api.whatsapp.com/send?phone=978864772&text=Quiero invertir || monto: ${amount}, meses: ${months}, nombre: ${formData.name}, apellido: ${formData.lastname}, Email: ${formData.email}, Celular: ${formData.subject}, mensaje: ${formData.message}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="loan-calculator-margin-extra ">
      <div className={`loan-calculator__form ${step === 1 ? 'visible' : 'hidden'}`} id="loan-calculator" data-interest-rate={15}>
       <div className="block-title text-left">
          <h2 className="block-title__title text-center" style={{ fontSize: "42px!important" }}>
            Consulta tu préstamo
          </h2>
        </div>

        <div className="slider-container">
          <p>Quiero solicitar</p>
          <div className="slider-wrapper">
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="thumb-extra color-secondary"
              trackClassName="track-extra  color-secondary"
              min={15000}
              max={500000}
              value={amount}
              onChange={(value) => setAmount(value)}
              renderThumb={(props, state) => (
                <div {...props}>
                  <div className="slider-thumb-value background-secondary">S/.{state.valueNow}</div>
                </div>
              )}
            />
          </div>
          <div className="slider-values">
            <span>15000</span>
            <span>500000</span>
          </div>
        </div>

        <div className="mb-20 mt-20">Selecciona los meses:</div>
        <div className="slider-container">
          <div className="slider-wrapper">
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="thumb-extra"
              trackClassName="track-extra"
              min={6}
              max={36}
              step={6}
              value={months}
              onChange={(value) => setMonths(value)}
              renderThumb={(props, state) => (
                <div {...props}>
                  <div className="slider-thumb-value background-secondary">{state.valueNow} Meses</div>
                </div>
              )}
            />
          </div>
          <div className="slider-values">
            <span>6 Meses</span>
            <span>36 Meses</span>
          </div>
        </div>

        <button className="thm-btn thm-btn--dark-hover background-secondary" onClick={handleNextStep}>
          Siguiente
        </button>
      </div>

      <div className={`loan-calculator__form ${step === 2 ? 'visible' : 'hidden'}`} id="form-contact">
        <form className="form-one contact-form-validated">
          <div className="row row-gutter-y-20 row-gutter-x-20">
            <div className="col-md-6">
              <input
                className="form-input"
                type="text"
                placeholder="Nombres"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-lg-6">
              <input
                className="form-input"
                type="text"
                placeholder="Apellidos"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-lg-6">
              <input
                className="form-input"
                type="email"
                placeholder="Correo"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6">
              <input
                className="form-input"
                type="text"
                placeholder="celular"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-lg-12">
              <textarea
                className="form-input"
                placeholder="Mensaje"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-12 ">
              <input type="hidden" name="loanAmount" id="loanAmount" value={amount} />
              <input type="hidden" name="loanMonths" id="loanMonths" value={months} />
              <button type="button" className="thm-btn br-4 background-secondary" onClick={handleSubmit}>
                Consultar
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className={`loan-calculator__form ${step === 3 ? 'visible' : 'hidden'}`} id="finish-form">
        <div className="row">
          <div className="col-lg-12 service-card-three__icon--black center d-flex-center-gap-14"></div>
          <div className="col-lg-10 service-card-three__icon--black center d-flex-center-gap-14 mt-40">
            <div>En unos minutos un asesor se pondrá en contacto contigo</div>
          </div>
          <div className="col-lg-12 mt-40 d-flex">
            <button type="button" className="thm-btn br-4" onClick={handleSubmit}>
              Enviar a WhatsApp
            </button>
          </div>
          <div className="col-lg-12 mt-40">
            <button type="button" className="thm-btn br-4" onClick={() => setStep(1)}>
              Volver a consultar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanSimulatorExtra;
