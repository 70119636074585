import React from 'react';
import { NavLink } from 'react-router-dom';

function MobileNav({setShowMenu}) {
  const toggleDropdown = (e) => {
    setShowMenu(false);
  };

  return (

    <div className="mobile-nav__wrapper expanded">
      <div className="mobile-nav__overlay mobile-nav__toggler" />
      <div className="mobile-nav__content">
        <span className="mobile-nav__close mobile-nav__toggler">
          <i className="fa fa-times"  onClick={setShowMenu}/>
        </span>
        <div className="logo-box">
          <NavLink to="/" aria-label="logo image"  onClick={setShowMenu}>
            <img src="assets/images/logos/logo-crea-white.png" width={155} alt="" />
          </NavLink>
        </div>
        <nav className="mobile-nav__container">
          <ul className="main-menu__list">
          <li className="dropdown">
              <NavLink to="/"  onClick={setShowMenu}>Inicio</NavLink>
            </li>
            <li>
              <NavLink to="/nosotros"  onClick={setShowMenu}>Nosotros</NavLink>
            </li>
            <li className="dropdown" id="invertir">
              <NavLink to="/invertir"  onClick={setShowMenu}>Invertir</NavLink>
            </li>
            <li className="dropdown" id="prestamos">
              <NavLink to="/prestamos"  onClick={setShowMenu}>Préstamos</NavLink>
            </li>
            <li className="dropdown">
              <NavLink to="/contacto"  onClick={setShowMenu}>Contáctanos</NavLink>
            </li>
          </ul>
        </nav>
        <ul className="mobile-nav__contact list-unstyled">
          <li>
            <i className="icon-email" />
            <a href="mailto:hola@creacapital.com.pe">hola@creacapital.com.pe</a>
          </li>
          <li>
            <i className="icon-telephone" />
            <a href="tel:991983888">991 983 888</a>
          </li>
        </ul>
        <div className="mobile-nav__social">
          <a id="tk" target="_blank" href="https://www.tiktok.com/@crea.capital.pe?_t=8mIfBdnF5Of&_r=1">
            <i className="fab fa-tiktok" />
          </a>
          <a id="fb" target="_blank" href="https://www.facebook.com/crea.capital.pe/">
            <i className="fab fa-facebook" />
          </a>
          <a id="lnk" target="_blank" href="https://www.linkedin.com/company/91594266/admin/feed/posts/">
            <i className="fab fa-linkedin-in" />
          </a>
          <a id="ig" target="_blank" href="https://www.instagram.com/crea.capital.pe?igsh=MWtncnVvdzcwYThqcw%3D%3D&utm_source=qr">
            <i className="fab fa-instagram" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
