import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import AgeFooter from './Layout/AgeFooter';
import Footer from './Layout/Footer';
import MobileNav from './Layout/MobileNav';
import Nav from './Layout/Nav';
import Topbar from './Layout/Topbar';
import Inicio from './Pages/Inicio';
import Nosotros from './Pages/Nosotros';
import Invertir from './Pages/Invertir';
import Prestamos from './Pages/Prestamo';
import Contacto from './Pages/Contacto';
import PreguntasFrecuentesCrea from './Pages/PreguntasFrecuentesCrea';
import PreguntasFrecuentesExtra from './Pages/PreguntasFrecuentesExtra';
import LoadScripts from './LoadScript';
import './App.css';
import FaqsExtra from './Pages/FaqsExtra';
import NoticiasCrea from './Pages/NoticiasCrea';
import NoticiasExtra from './Pages/NoticiasExtra';

function App() {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <Router>
      <LoadScripts/>
      <div className="App">
        <div className="custom-cursor">
          <div className="custom-cursor__cursor" />
          <div className="custom-cursor__cursor-two" />
          <div className="page-wrapper">
            <Topbar />
            <Nav setShowMenu={toggleMobileNav} />
              <Routes>
                <Route path="/" element={<Inicio />} />
                <Route path="/nosotros" element={<Nosotros />} />
                <Route path="/invertir" element={<Invertir />} />
                <Route path="/prestamos" element={<Prestamos />} />
                <Route path="/contacto" element={<Contacto />} />
                <Route path="/preguntas-frecuentes-crea" element={<PreguntasFrecuentesCrea />} />
                <Route path="/preguntas-frecuentes-extra" element={<PreguntasFrecuentesExtra />} />
                <Route path="/noticias-crea" element={<NoticiasCrea/>} />
                <Route path="/noticias-extra" element={<NoticiasExtra />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            <Footer />
          </div>
          <AgeFooter />
          {isMobileNavOpen && <MobileNav setShowMenu={toggleMobileNav}/>}
         {/*  <MobileNav showMenu={showMenu} /> */}
          <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
            <i className="fa fa-angle-up" />
          </a>
        </div>
      </div>
    </Router>
  );
}

export default App;
