import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';

const CarouselComponentV2 = () => {
  // Opciones para Owl Carousel
  const owlOptions = {
    loop: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    items: 1,
    autoplay: true,
    autoplayTimeout: 7000,
    smartSpeed: 500,
    nav: false,
    dots: false,
    margin: 0,
  };

  return (
    <section className="slider-one">
      <OwlCarousel options={owlOptions} className="thm-owl__carousel thm-owl__carousel--custom-nav owl-carousel owl-theme owl-dot-style-one">
        <div className="item">
          <div className="slider-one__item">
            <div className="slider-one__lines">
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div
              className="slider-one__image"
                style={{ backgroundImage: "url(assets/images/backgrounds/extra-1.png)" ,opacity: "0.5"}}
            ></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <p className="slider-one__tagline slider-one__tagline__secondary">
                    <i className="fa fa-chart-pie" />
                    EVALUACIÓN RÁPIDA Y EFICIENTE
                  </p>
                  <h2 className="slider-one__title">
                    Préstamos con garantía hipotecaria
                  </h2>
                  <p className="slider-one__text">
                    Extra Capital marca oficial de Crea Capital Group
                  </p>
                  <div className="slider-one__btns">
                    <a
                      href="https://api.whatsapp.com/send?phone=51978864772&text=%C2%A1Hola!%20Quiero%20un%20pr%C3%A9stamo%20con%20garant%C3%ADa%20hipotecaria%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F"
                      target="_blank"
                      className="thm-btn-4"
                      rel="noopener noreferrer"
                    >
                      Quiero un préstamo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
      
      <div className="slider-one__carousel__btn">
        {/* <button className="slider-one__carousel__btn__left">
              <i className="fa fa-angle-left"></i>
          </button>
          <button className="slider-one__carousel__btn__right">
              <i className="fa fa-angle-right"></i>
          </button> */}
      </div>
      <div className="slider-one__box wow fadeInRight" data-wow-duration="1500ms">
        <div
          className="slider-one__box__icon"
          style={{ backgroundColor: "#FF8600" }}
        >
          <i className="icon-successful" style={{ color: "#fff" }} />
        </div>
        <div className="slider-one__box__content" style={{ paddingTop: 24 }}>
          <p className="slider-one__box__tagline color-secondary">
            Hemos desembolsado más de
          </p>
          <h3 className="slider-one__box__title">
            <span style={{ fontSize: 38 }}>S/ 7'869,246.00</span> <br /> en proyectos peruanos.
          </h3>
        </div>
      </div>
    </section>
  );
};

export default CarouselComponentV2;
