import React, { useState } from 'react';

function Contacto() {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construye el mensaje de WhatsApp
    const whatsappMessage = `Hola, mis datos son:\n` +
      `- Nombres: ${formData.name}\n` +
      `- Apellidos: ${formData.lastname}\n` +
      `- Correo: ${formData.email}\n` +
      `- Celular: ${formData.phone}\n` +
      `- Mensaje: ${formData.message}\n\n` +
      `Me gustaría saber más sobre los servicios de Crea Capital.`;

    // Codifica el mensaje para la URL de WhatsApp
    const encodedMessage = encodeURIComponent(whatsappMessage);

    // Construye el enlace de WhatsApp
    const whatsappUrl = `https://wa.me/+51978866217?text=${encodedMessage}`;

    // Redirige a WhatsApp
   return window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content" />
      </div>
      <section className="page-header">
        <div className="page-header__bg" style={{ backgroundImage: "url(assets/images/backgrounds/office-workers-using-finance-graphs.jpg)" }} />
        <div className="container"></div>
      </section>
      <section className="blog-grid">
        <div className="container">
          <section className="contact-form--white pt-80 pb-80">
            <div className="col-lg-12 col-md-12">
              <div className="container">
                <div className="row row-gutter-y-30">
                  <div className="col-lg-5 col-md-6">
                    <div className="block-title">
                      <h2 className="block-title__title">Contáctanos</h2>
                    </div>
                    <div>
                      <p>¿Tienes alguna pregunta o necesitas más información? Completa el formulario a continuación y uno de nuestros asesores se pondrá en contacto contigo lo antes posible.</p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6">
                    <form id="contact-form" className="form-one contact-form-validated" onSubmit={handleSubmit}>
                      <div className="row row-gutter-y-20 row-gutter-x-20">
                        <div className="col-md-6">
                          <input
                            className="form-input"
                            type="text"
                            placeholder="Nombres"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-input"
                            type="text"
                            placeholder="Apellidos"
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-input"
                            type="email"
                            placeholder="Correo"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-input"
                            type="text"
                            placeholder="Número de contacto"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <textarea
                            className="form-input"
                            placeholder="Mensaje"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <button type="submit" className="thm-btn br-4">Consultar</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-telephone" />
                </div>
                <div className="contact-info-one__content">
                  <a className="contact-info-one__link" href="tel:954412310">+51 954 412 310</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-email" />
                </div>
                <div className="contact-info-one__content">
                  <a className="contact-info-one__link" href="mailto:hola@creacapital.com.pe">hola@creacapital.com.pe</a>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin" />
                </div>
                <div className="contact-info-one__content">
                  <a className="contact-info-one__link" href="">Av. Camino Real 348 - Torre el Pilar of. 706, San Isidro</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="google-map__default br-4">
          <iframe
            title="template google map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3903.453772071781!2d-77.0364316!3d-12.0969229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c85cd53b505d%3A0x8371f2ff41a418af!2sTorre%20El%20Pilar%2C%20Centro%20Camino%20Real%2C%20Av.%20Camino%20Real%20348%2C%20San%20Isidro%2015073!5e0!3m2!1sen!2spe!4v1641605258949!5m2!1sen!2spe"
            className="map__default"
            allowFullScreen=""
          />
        </div>
      </section>
    </>
  );
}

export default Contacto;
