import React, { useEffect, useRef } from 'react';
import noUiSlider from 'nouislider';
import CarouselComponentV2 from '../components/CarouselV2';
import LoanSimulatorExtra from '../components/LoanExtra';

function Prestamos() {
  const amountSliderRef = useRef(null);
  const monthsSliderRef = useRef(null);

  const requisitos = [
    {
      icon: "assets/images/icons/copia-literal.svg",
      number: "01",
      title: "Copia literal del inmueble",
      description: "Puedes adquirirlo de manera online o en las oficinas de SUNARP."
    },
    {
      icon: "assets/images/icons/predio-urbano.svg",
      number: "02",
      title: "Predio urbano (PU) y Hoja Resumen (HR)",
      description: "Puedes solicitarlos en la municipalidad de tu distrito."
    },
    {
      icon: "assets/images/icons/DNI.svg",
      number: "03",
      title: "Documento de identidad",
      description: "DNI ambas caras de propietarios y solicitantes del préstamo."
    },
    {
      icon: "assets/images/icons/ubi.svg",
      number: "04",
      title: "Ubicación por Google Maps",
      description: "Enviar la ubicación por WhatsApp."
    },
    {
      icon: "assets/images/icons/fotografia.svg",
      number: "05",
      title: "Adjuntar fotografías",
      description: "Fachada e interiores del inmueble."
    },
    {
      icon: "assets/images/icons/sustentacion.svg",
      number: "06",
      title: "Sustento de ingresos",
      description: "De los 03 últimos meses."
    },
    {
      icon: "assets/images/icons/sustentacion-calculate.svg",
      number: "07",
      title: "Tasación",
      description: "Solo en caso de contar con tasación, adjuntarla."
    },
  ];

  useEffect(() => {
    const amountSlider = amountSliderRef.current;
    const monthsSlider = monthsSliderRef.current;

    if (amountSlider && monthsSlider) {
      noUiSlider.create(amountSlider, {
        start: [15000],
        connect: [true, false],
        range: {
          min: 15000,
          max: 500000,
        },
        step: 1000,
      });

      amountSlider.noUiSlider.on('update', (values, handle) => {
        const amountValueElement = document.getElementById('amount-value');
        if (amountValueElement) {
          amountValueElement.innerHTML = `$${Math.round(values[handle])}`;
        }
      });

      noUiSlider.create(monthsSlider, {
        start: [6],
        connect: [true, false],
        range: {
          min: 6,
          max: 36,
        },
        step: 1,
      });

      monthsSlider.noUiSlider.on('update', (values, handle) => {
        const monthsValueElement = document.getElementById('months-value');
        if (monthsValueElement) {
          monthsValueElement.innerHTML = `${Math.round(values[handle])} Meses`;
        }
      });
    }

    // Cleanup
    return () => {
      if (amountSlider && monthsSlider) {
        amountSlider.noUiSlider.destroy();
        monthsSlider.noUiSlider.destroy();
      }
    };
  }, []);
  useEffect(() => {
    const addCustomClasses = () => {
      const owlCarouselElement = document.querySelector('.thm-owl__carousel');
      if (owlCarouselElement) {
        // Agregar clases necesarias
        owlCarouselElement.classList.add('owl-loaded'); // Agrega la clase .owl-loaded
        owlCarouselElement.classList.remove('no-js'); // Remueve la clase .no-js si está presente
      }
    };

    addCustomClasses();

  }, []);// La dependencia vacía [] asegura que useEffect se ejecute solo una vez

  return (
   <>
  <div className="stricky-header stricked-menu main-menu">
    <div className="sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
 <CarouselComponentV2/>
  {/* /.slider-one */}
  <section className="blog-grid ">
    <div className="container">
      <section className="contact-form--white pt-80 pb-80">
        <div className="col-lg-12 col-md-12">
          <div className="container">
            <div className="row row-gutter-y-30">
              <div className="col-lg-7 col-md-6">
                <div className="block-title ">
                  <p
                    className="block-title__tagline "
                    style={{ color: "black" }}
                  >
                    PRÉSTAMOS
                  </p>
                  {/*    */}
                  {/* /.block-title__tagline */}
                  <h2
                    className="block-title__title"
                    style={{ color: "#FF8600" }}
                  >
                    {" "}
                    Financia tus sueños con garantía
                  </h2>
                  {/* /.block-title__title */}
                </div>
                {/* /.block-title */}
                <div className="justify">
                  <p>
                    Extra Capital, una marca de Crea Capital Group SAC, se
                    especializa en ofrecer préstamos con garantía hipotecaria a
                    emprendedores y MYPES.
                  </p>
                  <p>
                    Nuestro objetivo es proporcionar el capital necesario para
                    que puedas hacer crecer tu negocio de manera segura y
                    eficiente. Ya sea que necesites iniciar un nuevo proyecto o
                    expandir uno existente, estamos aquí para ayudarte.
                  </p>
                </div>
                <div className="row alg-center">
                  <div className="col-lg-6">
                    <ul className="list-unstyled ml-0 about-two__list">
                      <p>
                        {" "}
                        Si eres un emprendedor en busca de capital Solicita más
                        información
                      </p>
                    </ul>
                  </div>
                  <div className="col-lg-4">
                    <div style={{ textAlign: "center" }} className='f-column'>
                      <i
                        className="icon-loan"
                        style={{
                          color: "black",
                          fontSize: 64,
                          marginBottom: 20
                        }}
                      />
                      <a
                        href="https://api.whatsapp.com/send?phone=51978864772&text=%C2%A1Hola!%20Quiero%20un%20pr%C3%A9stamo%20con%20garant%C3%ADa%20hipotecaria%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F"
                        className="thm-btn-2 main-menu__btn"
                        target="_blank"
                      >
                        Solicitar un préstamo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 hide-mobile">
                <div className="about-two__image--orange">
                  <img
                    src="assets/images/resources/extra_3_1.svg"
                    alt="extra-3"
                  />
                </div>
                {/* /.about-two__image */}
              </div>
            </div>
           
          </div>
          {/* /.container */}
        </div>
      </section>
      {/* /.faq-form */}
    </div>
    {/* /.container */}
  </section>
  {/* /.blog-grid */}
  <section className="service-two  pb-120">
    <section className="blog-grid ">
      <div className="container">
        <section className="contact-form">
          <div className="col-lg-12 col-md-12">
            <div className="container">
              <div className="row row-gutter-y-30">
                <div className="col-lg-12 col-md-6">
                  <div className="block-title ">
                    {/*    */}
                    {/* /.block-title__tagline */}
                    <h2
                      className="block-title__title--white center"
                      style={{ fontSize: 45, textAlign: "center" }}
                    >
                      Beneficios
                    </h2>
                    {/* /.block-title__title */}
                  </div>
                  {/* /.block-title */}
                  <div className="row center">
                    <div className="col-lg-5">
                      <div
                        className="about-two__image--orange"
                        style={{ top: "-2%" }}
                      >
                        <img src="assets/images/resources/extra_4.svg" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-1" />
                    <div className="col-lg-6" style={{ marginTop: "" }}>
                      <div className="col-lg-10 service-card-three__icon--black center">
                        <div className="d-flex-center-gap-14--row">
                          <div className="col-lg-1">
                            <i
                              className="icon-briefcase"
                              style={{ color: "#FF8600" }}
                            />
                          </div>
                          <div className="col-lg-11 ">
                            <div className="block-title__title--orange text-16">
                              <span className="color-secondary">
                                FINANCIAMIENTO
                              </span>
                            </div>
                            <div className="block-title__title--orange text-16" style={{marginTop:'-20px'}}>
                              Obtén préstamos con garantía hipotecaria desde
                              S/10 MIL hasta s/ 400 MIL
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-10 service-card-three__icon--black center">
                        <div className="d-flex-gap-14">
                          <div className="d-flex-center-gap-14--row">
                            <div className="col-lg-1">
                              <i
                                className="icon-briefcase"
                                style={{ color: "#FF8600" }}
                              />
                            </div>
                            <div className="col-lg-11 ">
                              <p className="block-title__title--orange text-16">
                                <span className="color-secondary">
                                  CAPACIDAD
                                </span>
                                <br />
                              </p>
                              <p className="block-title__title--orange text-16" style={{marginTop:'-20px'}}>
                                Préstamos hasta el del valor del inmueble
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-10 service-card-three__icon--black center">
                        <div className="d-flex-gap-14">
                          <div className="d-flex-center-gap-14--row">
                            <div className="col-lg-1">
                              <i
                                className="icon-briefcase"
                                style={{ color: "#FF8600" }}
                              />
                            </div>
                            <div className="col-lg-11 ">
                              <p className="block-title__title--orange text-16">
                                <span className="color-secondary">
                                  COBERTURA
                                </span>
                              </p>
                              <p className="block-title__title--orange text-16" style={{marginTop:'-20px'}}>
                                 Abarcamos todo Lima Metropolitana y algunos
                                sectores de provincias, previa evaluación.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container */}
          </div>
        </section>
        {/* /.faq-form */}
      </div>
      {/* /.container */}
    </section>
    {/* /.blog-grid */}
    <div className="service-two__shape--right" />
    {/* /.row */}
    {/*  </div> */}
  </section>
  {/* /.service-two */}
  <section
    className="service-two b pt-120"
    style={{ backgroundColor: "white" }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="block-title text-left">
            {/* <p class="block-title__tagline">SOBRE NOSOTROS</p> */}
            {/* /.block-title__tagline */}
            <h2 className="block-title__title color-secondary text-center ">
              REQUISITOS
            </h2>
            <div className="d-flex-center-gap-14--row mt-80">
  {requisitos.map((req, index) => (
    index < 3 && (
      <div key={index} className="col-lg-3 service-card-three__icon--black center">
        <div className="d-flex-justify">
          <div className="icon-4">
            <img className="icon-2" src={req.icon} alt={`icon-${req.number}`} />
          </div>
          <p className="mt-20 text-center" style={{ fontStyle: "italic" }}>
            <span className="list-number" style={{ fontStyle: "italic" }}>{req.number}</span>
            <span>
              <strong>{req.title}</strong>
              <br/>
            </span>{" "}
          </p>
        </div>
        <div><p className="text-center"><span>{req.description}</span></p></div>

      </div>
    )
  ))}
</div>
<div className="d-flex-center-gap-14--row mt-40">
{requisitos.map((req, index) => (
    index > 2 && (
      <div key={index} className="col-lg-3 service-card-three__icon--black center">
        <div className="d-flex-justify">
          <div className="icon-4">
            <img className="icon-2" src={req.icon} alt={`icon-${req.number}`} />
          </div>
          <p className="mt-20 text-center" style={{ fontStyle: "italic" }}>
            <span className="list-number" style={{ fontStyle: "italic" }}>{req.number}</span>
            <span>
              <strong>{req.title}</strong>
              <br/>
            </span>{" "}
          </p>
        </div>
        <div><p className="text-center"><span>{req.description}</span></p></div>

      </div>
    )
  ))}
</div>

            {/* /.block-title */}
          </div>
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  </section>
  <section className="service-two">
    {/* <div class="container"> */}
    <div className="service-two__shape" />
    {/* /.service-two__shape */}
    <div className="row row-gutter-y-20 d-flex-center">
      <h2 className="block-title__title--white text-center">
        Tipos de Proyectos
      </h2>
      <div className="col-lg-3 col-md-12">
        <div className="service-card-two">
          <div className="service-card-two__icon">
            <i className="icon-smartphone color-secondary" />
          </div>
          {/* /.service-card-two__icon */}
          <div className="service-card-two__content">
            <h3 className="service-card-two__title">
              <a href="services-details.html">1.Capital de Trabajo</a>
            </h3>
            {/* /.service-card-two__title */}
            <p className="service-card-two__text justify text-14">
              Impulsamos a empresarios ofreciendo financiamiento para adquirir
              mercadería o maquinaria vital para el crecimiento de sus negocios.
            </p>
            {/* /.service-card-two__text */}
            {/* /.service-card-two__link */}
          </div>
          {/* /.service-card-two__content */}
        </div>
        {/* /.service-card-two */}
      </div>
      {/* /.col-lg-4 col-md-12 */}
      <div className="col-lg-3 col-md-12">
        <div className="service-card-two">
          <div className="service-card-two__shape" />
          {/* /.service-card-two__shape */}
          <div className="service-card-two__icon">
            <i className="icon-operation color-secondary" />
          </div>
          {/* /.service-card-two__icon */}
          <div className="service-card-two__content">
            <h3 className="service-card-two__title">
              <a href="services-details.html">2.Construcción</a>
            </h3>
            {/* /.service-card-two__title */}
            <p className="service-card-two__text justify text-14">
              Proporcionamos préstamos para proyectos de construcción o
              remodelación, permitiendo a los propietarios alquilar o vender sus
              propiedades y generar ingresos adicionales.
            </p>
            {/* /.service-card-two__text */}
            {/* /.service-card-two__link */}
          </div>
          {/* /.service-card-two__content */}
        </div>
        {/* /.service-card-two */}
      </div>
      {/* /.col-lg-4 col-md-12 */}
      <div className="col-lg-3 col-md-12">
        <div className="service-card-two">
          <div className="service-card-two__shape" />
          {/* /.service-card-two__shape */}
          <div className="service-card-two__icon">
            <i className="icon-payment-gateway color-secondary" />
          </div>
          {/* /.service-card-two__icon */}
          <div className="service-card-two__content">
            <h3 className="service-card-two__title">
              <a href="services-details.html">3.Consolidación de Deudas</a>
            </h3>
            {/* /.service-card-two__title */}
            <p className="service-card-two__text justify text-14">
              Unificamos las deudas de nuestros clientes en un solo préstamo con
              mejores condiciones de tasa y pagos más manejables, brindando una
              solución eficiente para ordenar la situación financiera.
            </p>
            {/* /.service-card-two__text */}
            {/* /.service-card-two__link */}
          </div>
          {/* /.service-card-two__content */}
        </div>
        {/* /.service-card-two */}
      </div>
      {/* /.col-lg-4 col-md-12 */}
    </div>
    {/* /.row */}
    {/*  </div> */}
  </section>
  {/* /.service-two */}
  <section
   style={{
    backgroundImage: "url(assets/images/backgrounds/informativo_plantilla.svg)"
  }}
  >
    <div className="container">
      <div className="col-lg-12">
        <div className="row row-gutter-x-0">
       
          {/* /.block-title */}
          <div className="col-lg-5 pt-120 mb-120" style={{ maxHeight: 500 }}>
  
          <LoanSimulatorExtra/>
        
          </div>
          {/* /.col-lg-6 */}
          <div
            className="col-lg-7 about-two__image--orange hide-mobile"
            style={{ top: "5%", position: "relative", left: "8%" }}
          >
            <img
              style={{
                width: 700,
                top: "-14%",
                position: "relative",
                left: "8%"
              }}
              src="assets/images/resources/extra_simu.svg"
            />
          </div>
        </div>
        {/* /.row */}
      </div>
    </div>
  </section>
  <section className="service-two hide-mobile">
    <div className="container">
      <div className="col-lg-12 mt-40">
        <h2 style={{ color: "#fff", textAlign: "center", fontSize: 45 }}>
          Modalidades de Pago
        </h2>
        <div className="row mt-40">
          <div className="col-lg-6">
            <p
              style={{
                paddingLeft: 12,
                fontStyle: "italic",
                backgroundColor: "#FF8600",
                color: "#fff",
                textAlign: "center"
              }}
            >
              Método Francés
            </p>
          </div>
          <div className="col-lg-6">
            <p
              style={{
                paddingLeft: 12,
                fontStyle: "italic",
                backgroundColor: "#FF8600",
                color: "#fff",
                textAlign: "center"
              }}
            >
              Método Americano
            </p>
          </div>
          <div className="col-lg-6">
            <p
              style={{
                fontSize: 32,
                paddingLeft: 12,
                fontStyle: "italic",
                color: "white"
              }}
            >
              Cuota Fija
            </p>
          </div>
          <div className="col-lg-6">
            <p
              style={{
                fontSize: 32,
                paddingLeft: 12,
                fontStyle: "italic",
                color: "white"
              }}
            >
              Cuota Interés
            </p>
          </div>
          <div className="col-lg-6">
            <p style={{ paddingLeft: 12, fontStyle: "italic", color: "#fff" }}>
              {" "}
              Las cuotas mensuales son fijas durante todo el periodo definido.
              Se pagará la misma cantidad hasta conseguir la liquidación total
              del préstamo.
            </p>
          </div>
          <div className="col-lg-6">
            <p style={{ paddingLeft: 12, fontStyle: "italic", color: "#fff" }}>
            Las cuotas poseen un valor menor ya que solo están compuestas por el
              interés. En esta modalidad se deberá reintegrar la totalidad del
              capital prestado en la última cuota.
            </p>
          </div>
        </div>
      </div>
      {/* /.col-lg-6 */}
    </div>
  </section>
  <section className="service-two show-mobile">
    <div className="container">
      <div className="col-lg-12 mt-40">
        <h2 style={{ color: "#fff", textAlign: "center", fontSize: 45 }}>
          Modalidades de Pago
        </h2>
        <div className="row mt-40">
          <div className="col-lg-6">
            <p
              style={{
                paddingLeft: 12,
                fontStyle: "italic",
                backgroundColor: "#FF8600",
                color: "#fff",
                textAlign: "center"
              }}
            >
              Método Francés
            </p>
          </div>
          <div className="col-lg-6">
            <p
              style={{
                fontSize: 32,
                paddingLeft: 12,
                fontStyle: "italic",
                color: "white"
              }}
            >
              Cuota Fija
            </p>
          </div>
          <div className="col-lg-6">
            <p style={{ paddingLeft: 12, fontStyle: "italic", color: "#fff" }}>
              {" "}
              Las cuotas mensuales son fijas durante todo el periodo definido.
              Se pagará la misma cantidad hasta conseguir la liquidación total
              del préstamo.
            </p>
          </div>
          <div className="col-lg-6">
            <p
              style={{
                paddingLeft: 12,
                fontStyle: "italic",
                backgroundColor: "#FF8600",
                color: "#fff",
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Método Americano
            </p>
          </div>
          <div className="col-lg-6">
            <p
              style={{
                fontSize: 32,
                paddingLeft: 12,
                fontStyle: "italic",
                color: "white"
              }}
            >
              Cuota Interés
            </p>
          </div>
          <div className="col-lg-6">
            <p style={{ paddingLeft: 12, fontStyle: "italic", color: "#fff" }}>
              Las cuotas poseen un valor menor ya que solo están compuestas por el
              interés. En esta modalidad se deberá reintegrar la totalidad del
              capital prestado en la última cuota.
            </p>
          </div>
        </div>
      </div>
      {/* /.col-lg-6 */}
    </div>
  </section>
  <section className="service-one pt-120 pb-30">
    <img
      src="assets/images/shapes/service-h-1.png"
      className="service-one__icon-1"
      alt=""
    />
    <img
      src="assets/images/shapes/service-h-2.png"
      className="service-one__icon-2"
      alt=""
    />
    <img
      src="assets/images/shapes/service-h-3.png"
      className="service-one__icon-3"
      alt=""
    />
    <div className="container">
      <div className="block-title text-center">
        <h2 className="block-title__title ">
          {" "}
          ¡Contáctanos y obtén el préstamo que necesitas para alcanzar tus
          objetivos financieros!
          <br />{" "}
        </h2>
        {/* /.block-title__title */}
        <a
            className="thm-btn-2 thm-btn--dark-hover mt-60"
            href="https://api.whatsapp.com/send?phone=51978864772&text=%C2%A1Hola!%20Quiero%20un%20pr%C3%A9stamo%20con%20garant%C3%ADa%20hipotecaria%20%C2%BFPuede%20darme%20m%C3%A1s%20informaci%C3%B3n%3F"
            target="_blank"
            rel="noopener noreferrer"
          >
            Quiero un préstamo
          </a>
      </div>
      {/* /.block-title */}
    </div>
    {/* /.container */}
  </section>
  {/* /.service-grid pt-120 pb-120 */}
</>

  );
}
export default Prestamos;